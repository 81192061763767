.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.sidebar-closed {
  width: 80px;
  padding: 20px 10px;
}

.sidebar-logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2E74FF;  /* 햄버거 메뉴 아이콘 색상 변경 */
  justify-content: center;
}

.sidebar-open .sidebar-logo {
  justify-content: flex-start;
}

.sidebar-logo span {
  margin-left: 10px;
  font-weight: bold;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin: 0;
}

.sidebar-menu-item, .sidebar-menu li a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.sidebar-open .sidebar-menu-item, .sidebar-open .sidebar-menu li a {
  justify-content: flex-start;
}

.sidebar-closed .sidebar-menu-item, .sidebar-closed .sidebar-menu li a {
  justify-content: center;
}

.sidebar-menu-item:hover, .sidebar-menu li a:hover {
  background-color: #34495e;
  transform: translateX(5px);
}

.sidebar-menu-item {
  cursor: pointer;
  position: relative;
  justify-content: flex-start;
  gap: 10px;
  white-space: nowrap;
}

.sidebar-closed .sidebar-menu-item {
  justify-content: center;
  padding: 10px 0;
}

.sidebar-closed .sidebar-menu-item > svg:last-child {
  display: none;
}

.sidebar-menu li a {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.sidebar-closed .sidebar-menu li a {
  justify-content: center;
  padding: 10px 0;
}

.sidebar-menu-item:hover, .sidebar-menu li a:hover {
  background-color: #34495e;
}

.sidebar-menu-item {
  cursor: pointer;
  position: relative;
  justify-content: space-between;
}

.sidebar-menu-item > svg:last-child {
  position: absolute;
  right: 10px;
}

.sidebar-menu li span {
  margin-left: 10px;
}

.sidebar-submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-submenu li {
  margin: 0;
}

.sidebar-submenu li a {
  padding: 10px 10px 10px 30px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 8px;
  margin: 0 5px;
}

.sidebar-submenu li a svg {
  margin-right: 10px;
  font-size: 16px;
}

.sidebar-closed .sidebar-submenu li a svg {
  margin-right: 0;
}

.sidebar-submenu li a:hover {
  background-color: #34495e;
  transform: translateX(5px);
}

.sidebar-user-info {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-open .sidebar-user-info {
  align-items: flex-start;
}

.sidebar-username, .sidebar-tokens {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sidebar-username span, .sidebar-tokens span {
  margin-left: 5px;
}

.sidebar-tokens {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar-tokens:hover {
  background-color: #34495e;
}

.sidebar-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-settings-btn, .sidebar-logout-btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #34495e;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-open .sidebar-settings-btn, .sidebar-open .sidebar-logout-btn {
  justify-content: flex-start;
}

.sidebar-closed .sidebar-settings-btn, .sidebar-closed .sidebar-logout-btn {
  justify-content: center;
}

.sidebar-settings-btn:hover, .sidebar-logout-btn:hover {
  background-color: #3d566e;
}

.sidebar-settings-btn span, .sidebar-logout-btn span {
  margin-left: 10px;
}

.sidebar-closed .sidebar-logo span,
.sidebar-closed .sidebar-menu li span,
.sidebar-closed .sidebar-username span,
.sidebar-closed .sidebar-tokens span,
.sidebar-closed .sidebar-settings-btn span,
.sidebar-closed .sidebar-logout-btn span {
  display: none;
}

.sidebar-login-btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 12px;
  background-color: #1E90FF; /* 도저히 파란색 */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  border-radius: 5px;
  font-weight: bold;
}

.sidebar-open .sidebar-login-btn {
  justify-content: flex-start;
}

.sidebar-closed .sidebar-login-btn {
  justify-content: center;
}

.sidebar-login-btn:hover {
  background-color: #4169E1; /* 로얄 블루 */
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.sidebar-login-btn:active {
  transform: translateY(0);
}

.sidebar-login-btn span {
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-closed .sidebar-login-btn span {
  display: none;
}

.sidebar-mypage-btn,
.sidebar-logout-btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  box-sizing: border-box;
}

.sidebar-mypage-btn {
  background-color: #2E74FF; /* 요청하신 색상으로 변경 */
}

.sidebar-logout-btn {
  background-color: #e74c3c; /* 빨간색 */
}

.sidebar-open .sidebar-mypage-btn,
.sidebar-open .sidebar-logout-btn {
  justify-content: flex-start;
}

.sidebar-closed .sidebar-mypage-btn,
.sidebar-closed .sidebar-logout-btn {
  justify-content: center;
}

.sidebar-mypage-btn:hover,
.sidebar-logout-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.sidebar-mypage-btn:hover {
  background-color: #1E5FE6; /* 호버 시 약간 더 어두운 색상 */
}

.sidebar-logout-btn:hover {
  background-color: #c0392b; /* 조금 더 진한 빨간색 */
}

.sidebar-mypage-btn:active,
.sidebar-logout-btn:active {
  transform: translateY(0);
}

.sidebar-mypage-btn span,
.sidebar-logout-btn span {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-closed .sidebar-mypage-btn span,
.sidebar-closed .sidebar-logout-btn span {
  display: none;
}

.sidebar-menu-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.sidebar-closed .sidebar-menu-icon {
  width: 30px;
  height: 30px;
  transform: rotate(180deg);
}

.sidebar-menu-item:active, .sidebar-menu li a:active {
  transform: scale(0.98);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.sidebar-submenu li a:active {
  transform: scale(0.98);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.sidebar-menu-item.active, 
.sidebar-menu li a.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transform: none;  /* active 상태에서는 transform 효과 제거 */
}

.sidebar-submenu li a.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

/* 토큰, 마이페이지, 로그인 버튼 active 상태 */
.sidebar-tokens.active,
.sidebar-mypage-btn.active,
.sidebar-login-btn.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

/* 버튼들의 기본 스타일에 border-radius 추가 */
.sidebar-tokens,
.sidebar-mypage-btn,
.sidebar-login-btn,
.sidebar-logout-btn {
  border-radius: 8px;
  transition: all 0.2s ease;
}

/* active 상태일 때 transform 효과 제거 */
.sidebar-menu-item.active, 
.sidebar-menu li a.active,
.sidebar-submenu li a.active,
.sidebar-tokens.active,
.sidebar-mypage-btn.active,
.sidebar-login-btn.active {
  transform: none;
}

/* hover 효과 유지 */
.sidebar-menu-item:not(.active):hover, 
.sidebar-menu li a:not(.active):hover,
.sidebar-submenu li a:not(.active):hover {
  background-color: #34495e;
  transform: translateX(5px);
}

/* 모바일 네비게이션 바 스타일 */
.mobile-navigation {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2c3e50;
  padding: 10px 0;
  z-index: 1000;
}

.mobile-nav-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 12px;
}

.mobile-nav-item svg {
  font-size: 20px;
  margin-bottom: 4px;
}

.mobile-nav-item.active {
  color: #2E74FF;
}

/* 모바일 프로필 드로어 스타일 */
.mobile-profile-drawer {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  background-color: #2c3e50;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  z-index: 999;
}

.mobile-profile-drawer.open {
  transform: translateY(0);
}

.mobile-profile-header {
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-profile-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.mobile-profile-actions {
  padding: 15px;
}

.mobile-profile-actions a,
.mobile-profile-actions button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  border: none;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  font-size: 14px;
}

/* 미디어 쿼리 수정 */
@media (max-width: 768px) {
  .sidebar,
  .sidebar-open,
  .sidebar-closed {
    display: none !important;
    width: 0;
  }
  
  .mobile-navigation {
    display: block;
  }
  
  /* 메인 컨텐츠 여백 조정 */
  .page-content {
    margin-left: 0 !important;
    margin-bottom: 60px;
  }
}

/* 모바일 도구 서브메뉴 드로어 스타일 */
.mobile-tools-drawer {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  background-color: #2c3e50;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  z-index: 998;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.mobile-tools-drawer.open {
  transform: translateY(0);
}

.mobile-tools-list {
  padding: 15px;
}

.mobile-tools-list a {
  display: flex;
  align-items: center;
  padding: 15px;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.1);
}

.mobile-tools-list a.active {
  background-color: #2E74FF;
}

.mobile-tools-list a svg {
  margin-right: 12px;
  font-size: 18px;
}

.mobile-tools-list a span {
  font-size: 14px;
}

/* 드로어 오버레이 */
.mobile-drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.mobile-drawer-overlay.visible {
  opacity: 1;
  visibility: visible;
}

/* 모바일 알림 아이콘 스타일 */
.mobile-header-notification {
  position: relative;
  padding: 8px;
  cursor: pointer;
  color: #2c3e50;
}

.notification-badge {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background-color: #ff4757;
  border-radius: 50%;
}

/* 모바일 알림 팝업 스타일 */
.mobile-notification-popup {
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  max-width: 360px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  animation: slideIn 0.3s ease;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.mobile-notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.mobile-notification-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
}

.mobile-notification-header button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  font-size: 18px;
}

.mobile-notification-content {
  padding: 12px;
}

@keyframes slideIn {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 알림 팝업 오버레이 */
.mobile-notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {
  .mobile-notification-popup {
    right: 50%;
    transform: translateX(50%);
    width: 90%;
  }
}

/* 모바일 상단 헤더 스타일 */
.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 0 16px;
  box-sizing: border-box;
}

.mobile-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.mobile-header-logo {
  height: 24px;
  width: auto;
}

.mobile-header-notification {
  color: #2E74FF;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
}

@media (max-width: 768px) {
  .mobile-header {
    display: block;
  }
  
  .main-content {
    margin-top: 60px;
  }
  
  .page-content {
    margin-top: 60px !important;
  }
}
