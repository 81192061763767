.skills-section {
  padding: 20px;
}

.skill-category {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.skill-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 15px;
}

.skill-item button {
  margin-left: 5px;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
}

.add-skill-form {
  margin-top: 20px;
  padding: 20px;
  border: 1px dashed #ccc;
  border-radius: 8px;
}

.add-item-form {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.add-category-button {
  width: 100%;
  padding: 10px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} 