.evaluation-result-container {
  max-width: 1100px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f2f5;
}

.header-content h2 {
  font-size: 1.8rem;
  color: #2d3748;
  margin: 0;
}

.field-text {
  color: #718096;
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
}

.fit-badge {
  padding: 0.5rem 1.2rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 0.9rem;
  color: white;
}

.fit-badge.매우-적합 { background: #4CAF50; }
.fit-badge.적합 { background: #2196F3; }
.fit-badge.보통 { background: #FFC107; }
.fit-badge.부적합 { background: #FF5722; }

.score-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 16px;
}

.total-score-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.score-display {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 1rem;
}

.score-number {
  font-size: 3.5rem;
  font-weight: 700;
  color: #4F46E5;
  line-height: 1;
}

.score-label {
  font-size: 1.2rem;
  color: #718096;
  margin-left: 0.5rem;
}

.radar-chart-container {
  height: 300px;
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.feedback-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.feedback-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f2f5;
}

.card-icon {
  margin-right: 0.8rem;
  font-size: 1.2rem;
}

.strengths .card-icon { color: #4CAF50; }
.improvements .card-icon { color: #FFC107; }
.suggestions .card-icon { color: #2196F3; }

.feedback-card h3 {
  font-size: 1.2rem;
  color: #2d3748;
  margin: 0;
}

.feedback-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feedback-card li {
  padding: 0.8rem;
  margin-bottom: 0.8rem;
  background: #f8fafc;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #4a5568;
  line-height: 1.5;
}

.reset-button {
  display: block;
  margin: 2rem auto 0;
  padding: 0.8rem 2rem;
  background: #4F46E5;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
}

.reset-button:hover {
  background: #4338CA;
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .evaluation-result-container {
    padding: 1rem;
    margin: 0;
    
    box-sizing: border-box;
  }

  .result-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .header-content h2 {
    font-size: 1.5rem;
  }

  .fit-badge {
    align-self: flex-start;
  }

  .scores-section {
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1rem;
  }

  .feedback-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .feedback-card {
    padding: 1rem;
  }

  .card-header {
    padding-bottom: 0.5rem;
  }

  .action-buttons {
    flex-direction: column;
    gap: 0.8rem;
  }

  .primary-button,
  .secondary-button {
    width: 100%;
  }

  .ai-score-section {
    padding: 1rem;
  }

  .score-circles {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .circle-progress {
    width: 100px;
    height: 100px;
  }

  .circle-inner {
    width: 75px;
    height: 75px;
  }

  .score-value {
    font-size: 1.2rem;
  }

  .radar-chart-container {
    height: 250px;
    padding: 0.5rem;
  }

  .score-grid {
    grid-template-columns: 1fr;
  }

  .total-score-card {
    margin-bottom: 1rem;
  }
}

.scores-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin: 2rem 0;
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 16px;
}

.detailed-scores {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.score-bar-container {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.score-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #2d3748;
}

.score-value {
  font-weight: 600;
  color: #4F46E5;
}

.score-bar-wrapper {
  width: 100%;
  height: 8px;
  background: #edf2f7;
  border-radius: 4px;
  overflow: hidden;
}

.score-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 0.8s ease-out;
}

.total-score-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.radar-chart-container {
  flex-grow: 1;
  min-height: 300px;
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {
  .scores-section {
    grid-template-columns: 1fr;
  }
  
  .radar-chart-container {
    min-height: 250px;
  }
}

.ai-score-section {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.section-icon {
  font-size: 1.5rem;
  color: #4F46E5;
  margin-right: 1rem;
}

.score-circles {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  padding: 1rem 0;
}

.score-circle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.circle-progress {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle-inner {
  width: 90px;
  height: 90px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2d3748;
}

.score-label {
  font-size: 1rem;
  font-weight: 500;
  color: #4a5568;
}

@media (max-width: 768px) {
  .score-circles {
    flex-direction: column;
    align-items: center;
  }

  .circle-progress {
    width: 100px;
    height: 100px;
  }

  .circle-inner {
    width: 75px;
    height: 75px;
  }

  .score-value {
    font-size: 1.2rem;
  }
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem;
}

.primary-button,
.secondary-button {
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 150px;
}

.primary-button {
  background-color: #4F46E5;
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: #4338CA;
}

.secondary-button {
  background-color: white;
  color: #4F46E5;
  border: 2px solid #4F46E5;
}

.secondary-button:hover {
  background-color: #F3F4F6;
}

.detailed-feedback {
  margin-top: 2rem;
}

.detailed-feedback-content {
  padding: 1rem;
}

.feedback-item {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #F9FAFB;
  border-radius: 8px;
}

.original-text {
  font-weight: 500;
  color: #1F2937;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  border-left: 3px solid #4F46E5;
}

.feedback-comment {
  color: #4B5563;
  margin-left: 1rem;
}
  