.account-tab {
  max-width: 400px;
  margin: 40px auto 0; /* 상단 여백을 40px로 증가 */
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.account-tab h2 {
  text-align: left; /* 왼쪽 정렬로 변경 */
  margin-bottom: 30px;
  color: #333;
  font-weight: 600;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #555;
  text-align: left; /* 왼쪽 정렬로 변경 */
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.option-fail-test {
  margin-top: 0.5rem;
  font-size: 0.88rem;
  color: #dc3545;
}

.option-password-mismatch {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #dc3545;
}