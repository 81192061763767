.profile-tab {
  font-family: 'Roboto', Arial, sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(46, 116, 255, 0.1);
}

.user-section {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 24px;
}

.avatar-container {
  position: relative;
  cursor: pointer;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.avatar-edit-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2E74FF;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.camera-icon {
  color: white;
  font-size: 14px;
}

.avatar-container:hover .avatar {
  transform: scale(1.05);
}

.avatar-container:hover .avatar-edit-badge {
  background-color: #1c5cd4;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-details h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
}

.detail-icon {
  margin-right: 8px;
  color: #666;
}

.user-email, .user-nickname {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.vertical-line {
  width: 1px;
  background-color: #e0e0e0;
  margin: 0 40px;
}

.user-stats {
  display: flex;
  gap: 20px;
}

.stat-card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
  background-color: #f8f9ff;
  border-radius: 12px;
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
  min-width: 200px;
}

.stat-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 16px rgba(46, 116, 255, 0.15);
  background-color: #f0f4ff;
}

.stat-icon-container {
  background-color: #fff;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.stat-icon {
  width: 24px;
  height: 24px;
}

.stat-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stat-label {
  font-size: 13px;
  color: #666;
}

.stat-value {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

.stat-action {
  font-size: 13px;
  color: #2E74FF;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}

.arrow-icon {
  font-size: 12px;
  transition: transform 0.3s ease;
}

.stat-card:hover .arrow-icon {
  transform: translateX(4px);
}

.token-card {
  border: 1px solid rgba(46, 116, 255, 0.1);
}

.subscription-card {
  border: 1px solid rgba(46, 116, 255, 0.1);
}

.charge-button, .view-subscription-button {
  background-color: #2E74FF;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 600;
}

.charge-button:hover, .view-subscription-button:hover {
  background-color: #1c5cd4;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(46, 116, 255, 0.3);
}

.user-detail-section {
  background-color: #f0f4ff;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
}

.user-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-detail-header h3 {
  color: #2E74FF;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.edit-button {
  background-color: #2E74FF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.edit-button:hover {
  background-color: #1c5cd4;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(46, 116, 255, 0.3);
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.detail-item {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(46, 116, 255, 0.1);
  transition: transform 0.3s ease;
}

.detail-item:hover {
  transform: translateY(-5px);
}

.detail-label {
  font-weight: 600;
  color: #2E74FF;
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.detail-value {
  color: #333;
  font-size: 18px;
}

.user-detail-empty {
  text-align: center;
  padding: 30px;
  background-color: #f0f4ff;
  border-radius: 12px;
}

.user-detail-empty p {
  margin-bottom: 20px;
  color: #555;
  font-size: 18px;
}

.add-button {
  background-color: #2E74FF;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.add-button:hover {
  background-color: #1c5cd4;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(46, 116, 255, 0.3);
}

.tabs {
  display: flex;
  justify-content: center;
  background-color: #f0f4ff;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0 2px 10px rgba(46, 116, 255, 0.1);
}

.tabs a {
  text-decoration: none;
  color: #555;
  padding: 12px 25px;
  border-radius: 8px;
  transition: all 0.3s;
  font-size: 18px;
  font-weight: 600;
  margin: 0 10px;
}

.tabs a:hover, .tabs a.active {
  background-color: #2E74FF;
  color: #ffffff;
}

.saved-files-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.saved-files-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.saved-files-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.saved-files-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.file-card {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 20px;
  text-decoration: none;
  color: #333;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  gap: 20px;
}

.file-icon {
  font-size: 2rem;
  color: #2E74FF;
  padding: 15px;
  background-color: #f0f7ff;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.file-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  border-color: #2E74FF;
}

.file-card:hover .file-icon {
  background-color: #2E74FF;
  color: white;
}

.file-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.file-label {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.file-description {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
}

.file-category {
  display: none;
}

.user-detail-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

.detail-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.detail-value {
  color: #666;
}

.edit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button:hover {
  background-color: #0056b3;
}

/* 회원 탈퇴 버튼 */
.account-actions {
  margin-top: 30px;
  text-align: left; /* 왼쪽 정렬로 변경 */
}

.delete-account {
  background-color: transparent;
  color: #dc3545;
  border: 1px solid #dc3545;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.delete-account:hover {
  background-color: #dc3545;
  color: white;
}

.file-count small {
  display: block;
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
}

.file-category:hover .file-count small {
  color: #444;
}

@media (max-width: 768px) {
  .user-section {
    flex-direction: column; /* 세로 방향으로 변경 */
    align-items: center; /* 중앙 정렬 */
    padding: 20px; /* 패딩 추가 */
  }

  .user-info {
    margin: 0; /* 여백 제거 */
    padding: 0; /* 패딩 제거 */
    width: 100%; /* 너비를 100%로 설정 */
    justify-content: center; /* 중앙 정렬 */
  }

  .user-stats {
    display: flex; /* flexbox 사용 */
    flex-direction: column; /* 세로 방향으로 변경 */
    align-items: center; /* 중앙 정렬 */
    gap: 12px; /* 간격 추가 */
    margin-top: 20px; /* 상단 여백 추가 */
  }

  .stat-card {
    width: 100%; /* 너비를 100%로 설정 */
    min-width: unset; /* 최소 너비 설정 해제 */
  }
}