.complete-profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #ffffff; /* 흰 배경색 */
  }
  
  .complete-profile-page h1 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .complete-profile-page form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .complete-profile-page input,
  .complete-profile-page select,
  .complete-profile-page button {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .complete-profile-page input:focus,
  .complete-profile-page select:focus,
  .complete-profile-page button:focus {
    border-color: #3498db; /* 파란색 */
    outline: none;
  }
  
  .complete-profile-page .nickname-container {
    display: flex;
    align-items: center;
  }
  
  .complete-profile-page .nickname-container input {
    flex: 1;
  }
  
  .complete-profile-page .nickname-container button {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #3498db; /* 파란색 */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .complete-profile-page .nickname-container button:hover {
    background-color: #2980b9; /* 조금 더 어두운 파란색 */
  }
  
  .complete-profile-page .nickname-error {
    color: #e74c3c;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .complete-profile-page .nickname-success {
    color: #27ae60;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .complete-profile-page button[type="submit"] {
    background-color: #3498db; /* 파란색 */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .complete-profile-page button[type="submit"]:hover {
    background-color: #2980b9; /* 조금 더 어두운 파란색 */
  }