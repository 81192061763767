.confirm-step {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  position: relative;
  min-height: 600px;
}

.confirm-step__title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  text-align: left;
  padding-left: 20px;
}

.confirm-step__subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
  text-align: left;
  padding-left: 20px;
}

.confirm-step__content {
  background: #fff;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.confirm-step__info {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 40px;
  margin-bottom: 40px;
}

.confirm-step__photo {
  width: 100%;
  aspect-ratio: 3/4;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.confirm-step__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.confirm-step__details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
}

.confirm-step__detail-item {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.confirm-step__detail-label {
  font-size: 15px;
  color: #666;
  min-width: 120px;
  position: relative;
  padding-left: 12px;
}

.confirm-step__detail-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #4263eb;
}

.confirm-step__detail-value {
  font-size: 15px;
  color: #333;
  font-weight: 500;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
}

.confirm-step__detail-value--highlight {
  color: #4263eb;
}

.confirm-step__detail-value--upload {
  color: #666;
  font-weight: normal;
}

.confirm-step__detail-badge {
  padding: 4px 12px;
  background: #e7f5ff;
  color: #1971c2;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
}

.confirm-step__reference-photos {
  margin-top: 40px;
}

.confirm-step__reference-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.confirm-step__photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.confirm-step__reference-item {
  aspect-ratio: 3/4;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  height: 400px;
}

.confirm-step__reference-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.confirm-step__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}

.confirm-step__button {
  padding: 16px 48px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 140px;
}

.confirm-step__button--secondary {
  background: #f1f3f5;
  color: #333;
}

.confirm-step__button--primary {
  background: #4263eb;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.confirm-step__button--primary:disabled {
  background: #adb5bd;
  cursor: not-allowed;
}

.confirm-step__button--primary:hover {
  background: #364fc7;
}

.confirm-step__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media (max-width: 768px) {
  .confirm-step {
    padding: 20px 10px;
  }

  .confirm-step__title {
    font-size: 24px;
    padding-left: 10px;
  }

  .confirm-step__subtitle {
    font-size: 16px;
    padding-left: 10px;
  }

  .confirm-step__content {
    padding: 20px;
  }

  .confirm-step__info {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .confirm-step__photo {
    max-width: 250px;
    margin: 0 auto;
  }

  .confirm-step__photo-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .confirm-step__reference-item {
    height: 200px;
  }
} 

.spinning-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}