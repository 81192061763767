.resume-builder {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.resume-builder h1 {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.resume-sections {
  display: flex;
  gap: 40px;
  margin-top: 30px;
}

.left-section {
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.section-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.section-nav button {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
  color: #666;
  position: relative;
}

.section-nav button::before {
  content: counter(section);
  counter-increment: section;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-color: #e0e0e0;
  border-radius: 50%;
  font-size: 14px;
  color: #666;
}

.section-nav button.active {
  background-color: #2E74FF;
  color: white;
}

.section-nav button.active::before {
  background-color: white;
  color: #2E74FF;
}

.section-nav button:not(.active):hover {
  background-color: #f5f5f5;
  color: #2E74FF;
}

.section-nav button:not(.active):hover::before {
  background-color: #2E74FF;
  color: white;
}

.section-content {
  flex: 1;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.section-content-body {
  flex: 1;
}

.section-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.navigation-button {
  padding: 12px 24px;
  background: linear-gradient(135deg, #2E74FF, #1b5cd4);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.navigation-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.navigation-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.preview-button {
  padding: 10px 20px;
  background-color: #34495e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.preview-button:hover {
  background-color: #2c3e50;
}

.save-button {
  padding: 10px 20px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

.draft-button {
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

.resume-title-input {
  margin: 20px 0;
  width: 100%;
}

.title-input {
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.title-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.resume-info-box {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #2E74FF;
}

.resume-info-box h3 {
  color: #2E74FF;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
}

.resume-info-box p {
  color: #666;
  margin-bottom: 15px;
  font-size: 14px;
}

.service-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.service-item {
  display: flex;
  flex-direction: column;
  color: #555;
  font-size: 14px;
  font-weight: 500;
}

.service-item span {
  margin-top: 4px;
  margin-left: 24px;
  color: #666;
  font-weight: normal;
}

.next-button {
  padding: 10px 20px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.next-button:hover {
  background-color: #1b5cd4;
}

.next-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
  