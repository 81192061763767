.basic-info-section {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.basic-info-container {
  display: flex;
  gap: 40px;
  margin-top: 20px;
}

.photo-upload-section {
  flex-shrink: 0;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.photo-preview {
  width: 180px;
  height: 240px;
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.photo-preview:hover {
  border-color: #2E74FF;
  background-color: #f0f4ff;
}

.photo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.photo-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #666;
}

.photo-placeholder svg {
  width: 32px;
  height: 32px;
  color: #999;
}

.photo-upload-button {
  width: 100%;
  padding: 12px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(46, 116, 255, 0.2);
}

.photo-upload-button:hover {
  background-color: #1b5cd4;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(46, 116, 255, 0.3);
}

.info-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  margin-bottom: 0;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
  font-size: 14px;
}

.form-group input {
  width: 100%;
  padding: 14px;
  border: 1.5px solid #e0e0e0;
  border-radius: 8px;
  font-size: 15px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.form-group input:focus {
  border-color: #2E74FF;
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.form-group input::placeholder {
  color: #aaa;
} 