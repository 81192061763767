/* 질문과 초안 생성 버튼을 좌측에 배치 */
.question-response-block {
    display: flex;
    flex-direction: column;

    padding: 40px;
    margin-bottom: 10px;

    border: 1px solid white;
    border-radius: 10px;
    background-color: white;

    align-items: flex-start;
    position: relative;
}

  .question-items-group {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    gap: 12px;
    align-items: center;
  }

  .question-text {
    flex: 1;
    padding: 16px 20px;
    font-size: 15px;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    background-color: #f8faff;
    transition: all 0.2s ease;
    color: #333;
    font-weight: 500;
  }

  .question-text:focus {
    border-color: #2E74FF;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
    outline: none;
  }

  .question-text::placeholder {
    color: #a0a8bd;
    font-weight: 400;
  }

  .question-dropdown-button {
    padding: 16px;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    background-color: #ffffff;
    color: #2E74FF;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 54px;
    position: relative;
    overflow: hidden;
  }

  .question-dropdown-button:hover {
    background-color: #f0f7ff;
    border-color: #2E74FF;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.15);
  }

  .question-dropdown-button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(46, 116, 255, 0.2) 0%, transparent 70%);
    transform: scale(0);
    transition: transform 0.5s;
    border-radius: 50%;
  }

  .question-dropdown-button:active::after {
    transform: scale(2);
  }

  .question-dropdown-button span {
    transition: transform 0.3s ease;
  }

  .question-dropdown-button:hover span {
    transform: translateY(2px);
    animation: bounce 1s infinite;
  }

  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(3px); }
  }

  .question-dropdown-button::before {
    content: '가이드 질문 보기';
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    white-space: nowrap;
  }

  .question-dropdown-button:hover::before {
    opacity: 1;
    visibility: visible;
    top: -45px;
  }

  .question-dropdown-list {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 300px;
    background: white;
    border: 1px solid #e4ebff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(46, 116, 255, 0.1);
    padding: 8px;
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    transform-origin: top;
    animation: dropdownAppear 0.3s ease;
  }

  @keyframes dropdownAppear {
    from {
      opacity: 0;
      transform: translateY(-10px) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .question-dropdown-list li {
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #4a5568;
    font-weight: 500;
    margin: 4px 0;
    position: relative;
    overflow: hidden;
  }

  .question-dropdown-list li:hover {
    background-color: #f0f7ff;
    color: #2E74FF;
    padding-left: 20px;
  }

  .question-dropdown-list li::before {
    content: '→';
    position: absolute;
    left: -20px;
    opacity: 0;
    transition: all 0.3s ease;
  }

  .question-dropdown-list li:hover::before {
    left: 8px;
    opacity: 1;
  }

  .question-dropdown-button.active {
    background-color: #f0f7ff;
    border-color: #2E74FF;
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.15);
  }

  .question-dropdown-list::-webkit-scrollbar {
    width: 8px;
  }

  .question-dropdown-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  .question-dropdown-list::-webkit-scrollbar-thumb {
    background: #c5d1f8;
    border-radius: 4px;
  }

  .question-dropdown-list::-webkit-scrollbar-thumb:hover {
    background: #2E74FF;
  }

  .question-number {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #2E74FF;
    background-color: #f0f7ff;
    border: 2px solid #2E74FF;
    border-radius: 50%;
    margin-right: 20px;
    margin-bottom: 10px;
  } 
  
  .question-dropdown-list li {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0; /* Adds a separator line between items */
  }
  
  .question-dropdown-list li:last-child {
    border-bottom: none; /* Remove the bottom border for the last item */
  }
  
  .question-dropdown-list li:hover {
    background-color: #f0f0f0;
  }
  
  .generate-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .QuestionResponse-delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #007bff;
    font-size: 12pt;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .questionresponse-regenerate-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: #2E74FF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .questionresponse-regenerate-button:hover {
    background: #1b5cd4;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.1);
  }

  .generate-button:disabled,
  .questionresponse-regenerate-button:disabled {
    background-color: #ccc;
  }
  
  /* 답변 블록 스타일 */
  .response-block {
    width: 100%; /* 부모 요소가 100% 너비를 차지하도록 설정 */
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .response-block textarea {
    width: 100%; /* 가로 길이를 맞춤 */
    height: 300px; /* 세로 길이를 더 확보 */
    font-size: 17px; /* Increase font size */
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    resize: vertical; /* 사용자가 세로 크기를 조절할 수 있도록 설정 */
  }
  
  .questionResponse-response-container {
    display: flex;
    gap: 24px;
    width: 100%;
    margin-top: 15px;
    position: relative;
  }

  .questionResponse-response-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .response-textarea {
    width: 100%;
    min-height: 300px;
    padding: 20px;
    background: #ffffff;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    font-size: 15px;
    line-height: 1.7;
    color: #2d3748;
    resize: none;
    transition: all 0.2s ease;
    margin-bottom: 12px;
  }

  .response-textarea:focus {
    border-color: #2E74FF;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.08);
    outline: none;
  }

  .questionResponse-edit-section {
    width: 320px;
    flex-shrink: 0;
    background: #ffffff;
    border: 1px solid rgba(46, 116, 255, 0.08);
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    animation: slideInRight 0.3s ease;
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: sticky;
    top: 24px;
  }

  .edit-section-header {
    position: relative;
    padding-left: 16px;
    margin-bottom: 20px;
  }

  .edit-section-header::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(to bottom, #2E74FF, #6698ff);
    border-radius: 2px;
  }

  .edit-section-title {
    font-size: 16px;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 8px;
  }

  .edit-section-description {
    font-size: 13px;
    color: #718096;
    line-height: 1.6;
  }

  .selected-text-area {
    width: 100%;
    min-height: 90px;
    padding: 16px;
    background: #f8faff;
    border: 1px solid #e4ebff;
    border-radius: 12px;
    font-size: 14px;
    line-height: 1.6;
    color: #4a5568;
    resize: none;
    margin-bottom: 16px;
  }

  .prompt-section {
    margin-bottom: 16px;
  }

  .prompt-label {
    font-size: 13px;
    color: #4a5568;
    margin-bottom: 8px;
    display: block;
  }

  .prompt-input {
    width: 100%;
    padding: 14px;
    background: #ffffff;
    border: 1px solid #e4ebff;
    border-radius: 12px;
    font-size: 14px;
    transition: all 0.2s ease;
  }

  .response-textarea,
  .selected-text-area,
  .prompt-input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 17px;
    margin-bottom: 10px;
    font-family: inherit;
    background-color: white;
    box-sizing: border-box; 
  }

  .prompt-input input {
    width: 100%; /* input도 부모 요소의 폭을 따라가도록 설정 */
    padding: 10px;
    font-size: 17px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* padding과 border를 폭에 포함 */
}

  .response-textarea {
    height: 300px;
    resize: none;
    transition: color 0.3s ease; /* 부드러운 전 효과 */
  }

  .response-textarea.highlighted {
    color: red; /* 3초 동안 강조된 색상 */
  }

  .edit-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .edit-buttons button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .edit-buttons button:hover {
    background-color: #0056b3;
  }


  .questionresponse-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
    width: 95%;
    max-width: 600px;
    z-index: 1000;
    max-height: 90vh;
    overflow-y: auto;
  }

  .questionresponse-modal-content {
    position: relative;
    padding-right: 24px;
  }

  .questionresponse-modal-body {
    margin-top: 24px;
  }

  .questionresponse-modal-section {
    margin-bottom: 24px;
  }

  .questionresponse-modal-section h4 {
    font-size: 15px;
    color: #666;
    margin-bottom: 12px;
  }

  .questionresponse-modal-section p {
    background-color: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .revised-text-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 8px;
  }

  .revised-text-button {
    padding: 16px 16px 16px 40px;
    background: #f8faff;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;
    line-height: 1.6;
    text-align: left;
  }

  .revised-text-button::before {
    content: attr(data-option);
    position: absolute;
    left: 16px;
    color: #2E74FF;
    font-weight: 600;
  }

  .revised-text-button.selected {
    background: #f0f7ff;
    border-color: #2E74FF;
  }

  /* 스크롤바 스타일링 */
  .questionresponse-modal::-webkit-scrollbar,
  .revised-text-options::-webkit-scrollbar {
    width: 6px;
  }

  .questionresponse-modal::-webkit-scrollbar-track,
  .revised-text-options::-webkit-scrollbar-track {
    background: #f0f7ff;
    border-radius: 3px;
  }

  .questionresponse-modal::-webkit-scrollbar-thumb,
  .revised-text-options::-webkit-scrollbar-thumb {
    background: #2E74FF;
    border-radius: 3px;
  }

  .button-primary {
    background-color: #2E74FF;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .button-primary:hover {
    background-color: #1b5cd4;
    transform: translateY(-1px);
  }

  .button-secondary {
    background: none;
    border: 1px solid #2E74FF;
    color: #2E74FF;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .button-secondary:hover {
    background-color: #f0f7ff;
  }

  .textarea-common {
    width: 100%;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
    font-family: inherit;
    background-color: white;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  .textarea-common:focus {
    border-color: #2E74FF;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
    outline: none;
  }

  .modal-common {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    z-index: 1000;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    width: 500px;
    max-width: 90%;
    animation: modalFadeIn 0.3s ease;
  }

  @keyframes modalFadeIn {
    from { opacity: 0; transform: translate(-50%, -48%); }
    to { opacity: 1; transform: translate(-50%, -50%); }
  }

  /* 드래그 스타일 */
  ::selection {
    background: rgba(46, 116, 255, 0.15);
    color: #2E74FF;
  }

  /* 미니 수정기 스타일 */
  .mini-editor {
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 12px;
    z-index: 1000;
    border: 1px solid #e0e0e0;
    animation: fadeInUp 0.2s ease;
  }

  .mini-editor-buttons {
    display: flex;
    gap: 8px;
  }

  .mini-editor-button {
    padding: 6px 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .mini-editor-button.edit {
    background-color: #2E74FF;
    color: white;
  }

  .mini-editor-button.edit:hover {
    background-color: #1b5cd4;
  }

  .mini-editor-button.delete {
    background-color: #ff4d4d;
    color: white;
  }

  .mini-editor-button.delete:hover {
    background-color: #e63939;
  }

  .mini-editor-button.cancel {
    background-color: #f5f5f5;
    color: #666;
  }

  .mini-editor-button.cancel:hover {
    background-color: #e8e8e8;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* 드래그 중일 때 커서 스타일 */
  .question-response-block {
    cursor: text;
  }

  .question-response-block:hover {
    border-color: #e0e0e0;
  }

  /* 드래그 중일 때 배경 효과 */
  .dragging {
    background-color: rgba(46, 116, 255, 0.05);
    border-color: #2E74FF;
    transition: all 0.3s ease;
  }

  .question-input-container {
    margin-bottom: 24px;
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .question-input {
    width: 100%;
    min-height: 120px;
    padding: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    font-size: 16px;
    line-height: 1.6;
    resize: vertical;
    transition: all 0.2s ease;
    background-color: #fafafa;
    font-family: 'Pretendard', sans-serif;
  }

  .question-input:focus {
    outline: none;
    border-color: #2E74FF;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }

  .question-input::placeholder {
    color: #999;
  }

  .button-group {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .action-button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 120px;
  }

  .generate-button {
    background-color: #2E74FF;
    color: white;
  }

  .cancel-button {
    background-color: #ff4d4d;
    color: white;
  }

  .generate-button:hover {
    background-color: #1b5cd9;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.1);
  }

  .cancel-button:hover {
    background-color: #e63939;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(255, 77, 77, 0.1);
  }

  .reset-button {
    background-color: #ff4d4d;
    color: white;
  }

  .reset-button:hover {
    background-color: #e63939;
    transform: translateY(-1px);
  }

  .action-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    transform: none;
  }

  .selected-text-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 600px;
    z-index: 1000;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .modal-header {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    color: #333;
  }

  .modal-content {
    margin-bottom: 20px;
  }

  .questionresponse-edit-buttons {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 20px;
  }

  .questionresponse-edit-buttons button {
    min-width: 120px;
    padding: 12px 24px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
  }

  .questionresponse-edit-buttons button:first-child {
    background: #2E74FF;
    color: white;
  }

  .questionresponse-edit-buttons button:last-child {
    background: #ff4d4d;
    color: white;
  }

  .question-response-block {
    background: #ffffff;
    border: 1px solid #e4ebff;
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 24px;
    transition: all 0.3s ease;
    position: relative;
  }

  .question-number {
    width: 36px;
    height: 36px;
    background: linear-gradient(135deg, #2E74FF, #4c8dff);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .question-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .question-title {
    font-size: 18px;
    font-weight: 600;
    color: #2d3748;
    margin-left: 12px;
  }

  .response-textarea {
    width: 100%;
    min-height: 200px;
    padding: 20px;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    font-size: 15px;
    line-height: 1.7;
    background-color: #fafafa;
    transition: all 0.2s ease;
    margin-bottom: 16px;
  }

  .response-textarea:focus {
    border-color: #2E74FF;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }

  .response-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .response-guide {
    font-size: 13px;
    color: #666;
  }

  .char-count {
    font-size: 13px;
    color: #2E74FF;
    font-weight: 500;
  }

  .questionresponse-regenerate-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: #2E74FF;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 2;
  }

  .questionresponse-regenerate-button:hover {
    background: #1b5cd4;
    transform: translateY(-1px);
  }

  .questionresponse-regenerate-button:disabled {
    background: #e0e0e0;
    cursor: not-allowed;
    transform: none;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(3px);
  }

  /* 전체 컨테이너 스타일 개선 */
  .question-response-block {
    background: #ffffff;
    border: 1px solid #e4ebff;
    border-radius: 20px;
    padding: 32px;
    margin-bottom: 30px;
    box-shadow: 0 4px 20px rgba(46, 116, 255, 0.05);
    transition: all 0.3s ease;
  }

  .question-response-block:hover {
    box-shadow: 0 8px 30px rgba(46, 116, 255, 0.08);
    transform: translateY(-2px);
  }

  /* 질문 번호 디자인 개선 */
  .question-number {
    width: 40px;
    height: 40px;
    background: linear-gradient(135deg, #2E74FF, #4c8dff);
    color: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
  }

  /* 입력 필드 스타일 개선 */
  .question-items-group input {
    width: 100%;
    padding: 16px;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    font-size: 15px;
    transition: all 0.2s ease;
    background-color: #f8faff;
  }

  .question-items-group input:focus {
    border-color: #2E74FF;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }

  /* 버튼 스타일 개선 */
  .generate-button, .questionresponse-regenerate-button {
    background: linear-gradient(135deg, #2E74FF, #4c8dff);
    color: white;
    padding: 12px 24px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .generate-button:hover, .questionresponse-regenerate-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
  }

  /* 텍스트 영역 스타일 개선 */
  .response-textarea {
    width: 100%;
    min-height: 200px;
    padding: 20px;
    background: #fafafa;
    border: 2px solid #e4ebff;
    border-radius: 16px;
    font-size: 15px;
    line-height: 1.8;
    transition: all 0.2s ease;
    resize: vertical;
  }

  .response-textarea:focus {
    background-color: #ffffff;
    border-color: #2E74FF;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }

  /* 드롭다운 메뉴 스타일 개선 */
  .question-dropdown-list {
    background: white;
    border: 1px solid #e4ebff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 8px;
    margin-top: 8px;
  }

  .question-dropdown-list li {
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .question-dropdown-list li:hover {
    background-color: #f0f7ff;
    color: #2E74FF;
  }

  /* 로딩 상태 스타일 개선 */
  .loading-overlay {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
  }

  .loading-content {
    background: white;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .loading-text {
    color: #2E74FF;
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
  }

  /* 질문 헤더 영역 */
  .question-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 12px;
  }

  /* 질문 라벨 */
  .question-label {
    font-size: 14px;
    color: #4a5568;
    font-weight: 600;
    margin-bottom: 8px;
  }

  /* 모바일 대응 */
  @media (max-width: 768px) {
    .question-items-group {
      flex-direction: column;
    }
    
    .question-dropdown-button {
      width: 100%;
      margin-top: 8px;
    }
    
    .question-dropdown-list {
      width: 100%;
      left: 0;
    }
  }

  /* 버튼 내 로딩 애니메이션 */
  .button-spinner-dot {
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    animation: buttonLoadingDots 1s infinite;
  }

  .button-spinner-dot::before,
  .button-spinner-dot::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
  }

  .button-spinner-dot::before {
    left: -8px;
    animation: buttonLoadingDots 1s infinite 0.2s;
  }

  .button-spinner-dot::after {
    right: -8px;
    animation: buttonLoadingDots 1s infinite 0.4s;
  }

  @keyframes buttonLoadingDots {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-4px); }
  }

  /* 버튼 비활성화 상태 */
  .generate-button:disabled,
  .questionresponse-regenerate-button:disabled {
    opacity: 0.7;
    cursor: wait;
  }

  /* 버튼 내 텍스트 정렬 */
  .generate-button,
  .questionresponse-regenerate-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 100px;
  }

  /* X 버튼 스타일 수정 */
  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4d4d;  /* 빨간색으로 변경 */
  }

  .close-button:hover {
    color: #ff3333;  /* 호버 시 더 진한 빨간색 */
  }

  .close-button::before,
  .close-button::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: currentColor;
    border-radius: 1px;
  }

  .close-button::before {
    transform: rotate(45deg);
  }

  .close-button::after {
    transform: rotate(-45deg);
  }

  /* 생성 버튼 스타일 업데이트 */
  .questionResponse-edit-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .generate-edit-button {
    background: linear-gradient(135deg, #2E74FF, #4c8dff);
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
  }

  .generate-edit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
  }

  .generate-edit-button:disabled {
    opacity: 0.7;
    cursor: wait;
  }

  /* 모달 닫기 버튼 추가 */
  .questionresponse-modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    transition: color 0.2s ease;
  }

  .questionresponse-modal-close:hover {
    color: #ff4d4d;
  }

  .questionresponse-modal-close::before,
  .questionresponse-modal-close::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: currentColor;
    border-radius: 1px;
  }

  .questionresponse-modal-close::before {
    transform: rotate(45deg);
  }

  .questionresponse-modal-close::after {
    transform: rotate(-45deg);
  }

  .revised-text-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 300px;
    overflow-y: auto;
  }

  .revised-text-button {
    padding: 16px;
    background: #f8faff;
    border: 2px solid #e4ebff;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    word-break: break-word;
    white-space: pre-wrap;
    position: relative;
    padding-left: 40px;
  }

  .revised-text-button::before {
    content: attr(data-option);
    position: absolute;
    left: 16px;
    color: #2E74FF;
    font-weight: 600;
  }

  .revised-text-button.selected {
    background: #f0f7ff;
    border-color: #2E74FF;
  }

  .questionresponse-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
  }

  .questionresponse-apply-button,
  .questionresponse-cancel-button {
    padding: 14px 24px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .questionresponse-apply-button {
    background-color: #2E74FF;
    color: white;
    border: none;
  }

  .questionresponse-apply-button:hover {
    background-color: #1b64ff;
    transform: translateY(-1px);
  }

  .questionresponse-cancel-button {
    background-color: white;
    color: #2E74FF;
    border: 1px solid #2E74FF;
  }

  .questionresponse-cancel-button:hover {
    background-color: #f8f9fa;
    transform: translateY(-1px);
  }

  /* 모바일 최적화 스타일 추가 */
  @media screen and (max-width: 768px) {
    .generate-button, 
    .questionresponse-regenerate-button {
      width: 100%;
      padding: 16px 24px;
      font-size: 16px;
    }

    .response-textarea {
      min-height: 150px;
      padding: 16px;
      font-size: 14px;
    }

    .question-dropdown-list {
      max-height: 60vh;
      overflow-y: auto;
    }
  }

  /* 터치 인터페이스 최적화 */
  @media (hover: none) and (pointer: coarse) {
    .generate-button:hover, 
    .questionresponse-regenerate-button:hover {
      transform: none;
    }

    .response-textarea:focus {
      box-shadow: none;
    }
  }