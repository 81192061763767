.userDetailEdit {
  position: relative;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
}

.circularCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #6c757d;
  color: white;
  border: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0;
}

.circularCloseButton:hover {
  background-color: #5a6268;
}

.circularCloseButton span {
  line-height: 1;
}

.ude-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.ude-form-group {
  margin-bottom: 20px;
}

.ude-form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-weight: 500;
}

.ude-form-group input,
.ude-form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.ude-array-input-container {
  margin-top: 10px;
}

.ude-array-input-item {
  display: flex;
  margin-bottom: 10px;
}

.ude-array-input-item input {
  flex-grow: 1;
  margin-right: 10px;
}

.ude-array-input-item button,
.ude-add-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.ude-array-input-item button {
  background-color: #dc3545;
  color: white;
}

.ude-array-input-item button:hover {
  background-color: #c82333;
}

.ude-add-button {
  background-color: #28a745;
  color: white;
  margin-top: 10px;
}

.ude-add-button:hover {
  background-color: #218838;
}

.ude-form-actions {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.ude-save-button,
.ude-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.ude-save-button {
  background-color: #007bff;
  color: white;
}

.ude-save-button:hover {
  background-color: #0056b3;
}

.ude-cancel-button {
  background-color: #6c757d;
  color: white;
}

.ude-cancel-button:hover {
  background-color: #545b62;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(46, 116, 255, 0.2);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 30px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  color: #2E74FF;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  color: #2E74FF;
  cursor: pointer;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.input, .select {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.input:focus, .select:focus {
  outline: none;
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.arrayInputContainer {
  margin-top: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  overflow: hidden;
}

.arrayInputItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
}

.arrayInputItem:last-child {
  border-bottom: none;
}

.arrayInputItem input {
  flex-grow: 1;
  padding: 12px 15px;
  border: none;
  font-size: 16px;
}

.arrayInputItem input:focus {
  outline: none;
}

.removeButton {
  padding: 12px;
  background-color: transparent;
  color: #2E74FF;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
}

.removeButton:hover {
  background-color: #F0F4FF;
}

.addButton {
  width: 100%;
  padding: 12px;
  background-color: #F0F4FF;
  color: #2E74FF;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton:hover {
  background-color: #E0E8FF;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  background-color: #F0F4FF;
  border-top: 1px solid #E0E0E0;
}

.formActions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.saveButton, .cancelButton {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin: 0 10px;
}

.saveButton {
  background-color: #2E74FF;
  color: white;
}

.saveButton:hover {
  background-color: #1E5FD9;
}

.cancelButton {
  background-color: white;
  color: #2E74FF;
  border: 2px solid #2E74FF;
}

.cancelButton:hover {
  background-color: #F0F4FF;
}
