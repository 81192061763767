.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.signup-page {
  width: 400px;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.signup-page h1 {
  display: none;
}

.signup-subtitle {
  color: #666;
  margin-bottom: 30px;
}

.email-signup-btn,
.social-login {
  width: 360px;
  margin: 0 auto;
}

.email-signup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.email-signup-btn:hover {
  background-color: #0056b3;
}

.email-signup-btn .icon {
  margin-right: 10px;
}

.email-signup-btn a {
  color: white;
  text-decoration: none;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.divider span {
  padding: 0 10px;
  color: #777;
}

.signup-page .social-login {
  display: flex;
  flex-direction: column;
}

.signup-page .login-link {
  margin-top: 20px;
}

.header-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.social-login {
  width: 100%;
}

.social-login button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.social-login button:hover {
  background-color: #f8f9fa;
  border-color: #ccc;
}

.social-login button img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .signup-container {
    padding: 20px;
    height: auto;
    min-height: 100vh;
  }

  .signup-page {
    width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    margin: 0;
    box-shadow: none;
  }

  .email-signup-btn,
  .social-login {
    width: 100%;
  }

  .header-logo {
    width: 160px;
    margin-bottom: 15px;
  }

  .signup-subtitle {
    font-size: 14px;
    margin-bottom: 25px;
    padding: 0 10px;
    word-break: keep-all;
  }
}
