.education-section {
  padding: 20px;
}

.education-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
}

.form-row input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-button {
  width: 100%;
  padding: 12px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.delete-button {
  padding: 8px 15px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} 