.saved-photos {
  padding: 20px;
}

.saved-photos h2 {
  margin-bottom: 20px;
}

.photo-list {
  display: flex;
  flex-wrap: wrap;
}

.photo-item {
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  position: relative;
}
