.community-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #f0f4ff;
  border-radius: 20px;
  box-shadow: 0 0 40px rgba(46, 116, 255, 0.1);
}

h1 {
  font-size: 42px;
  margin-bottom: 40px;
  color: #2E74FF;
  text-align: center;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.community-controls {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(46, 116, 255, 0.1);
  margin-bottom: 30px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e0e0ff;
}

.tabs button {
  background-color: #f0f4ff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  color: #2E74FF;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;
  font-weight: 600;
}

.tabs button:hover {
  background-color: #d0d8ff;
}

.tabs button.active {
  background-color: #2E74FF;
  color: white;
}

.sort-write {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

select {
  padding: 10px 15px;
  border: 2px solid #2E74FF;
  border-radius: 20px;
  font-size: 14px;
  background-color: white;
  color: #2E74FF;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%232E74FF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%20-5.4-5.4-12.9z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12
}

.write-button {
  background-color: #2E74FF;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 15px rgba(46, 116, 255, 0.3);
}

.write-button:hover {
  background-color: #1c5cdb;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(46, 116, 255, 0.4);
}

.write-button::before {
  content: '+';
  margin-right: 8px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .community-page {
    padding: 15px 10px;
    margin: 0;
    border-radius: 0;
    background-color: #f8faff;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: left;
    padding-left: 5px;
  }

  .community-controls {
    padding: 12px;
    margin-bottom: 15px;
    box-shadow: 0 2px 8px rgba(46, 116, 255, 0.08);
  }

  .tabs {
    padding: 5px;
    margin-bottom: 15px;
    gap: 6px;
  }

  .tabs button {
    padding: 6px 12px;
    font-size: 12px;
    white-space: nowrap;
    flex-shrink: 0;
  }

  .sort-write {
    gap: 8px;
  }

  select {
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }

  .write-button {
    height: 40px;
    font-size: 14px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
