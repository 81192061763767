.resume-view-page {
  max-width: 1000px;
  margin: 40px auto;
  padding: 40px 20px;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

/* 제목 스타일 */
.resumeview-title-container {
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.resumeview-title {
  margin-bottom: 10px; /* 제목과 input 사이의 아래쪽 간격 */
  font-size: 24pt;
}

.resumeview-title-input {
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* 글자 수 표시 스타일 */
.char-count-container {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-bottom: 10px;
}

.cover-letter-view {
  max-height: 70vh; /* 화면의 70% 높이만큼 차지 */
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

.qa-container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  text-align: left; /* 글자 좌측 정렬 */
}

.qa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.qa-container h3 {
  margin: 0;
}

.resumeview-copy-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.resumeview-copy-button.copied {
  background-color: #007bff;
}

.copy-button:hover {
  background-color: #0056b3;
}

.copy-button.copied:hover {
  background-color: #0056b3;
}

.qa-container p {
  white-space: pre-wrap; /* 줄바꿈과 공백을 유지하도록 설정 */
  margin: 0;
}

.ResumeView-action-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.export-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

/* 모바일 반응형 스타일 */
@media screen and (max-width: 768px) {
  .resume-view-page {
    margin: 20px auto;
    padding: 20px 15px;
  }

  .resumeview-title {
    font-size: 20pt;
  }

  .cover-letter-view {
    max-height: none;
    padding: 15px;
  }

  .qa-container {
    padding: 8px;
  }

  .qa-header {
    flex-direction: column;
    gap: 8px;
  }
}