/* 전체적인 헤더 컨테이너 스타일 */
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 150px;  /* 좌우 여백을 150px로 설정 */
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  transition: background-color 0.3s ease;
  z-index: 10;
}

/* 로고 스타일 */
.header__logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #007bff;
  transition: color 0.3s ease;
}

.header__logo:hover {
  color: #0056b3;
}

.header__logo img {
  height: 40px;  /* 로고 이미지의 높이 설정, 필요에 따라 조정 */
  width: auto;  /* 비율 유지 */
}

/* 네비게이션 스타일 */
.header__nav {
  display: flex;
  gap: 1.5rem;
}

.header__auth {
  margin-left: 100px; /* 원하는 만큼 왼쪽으로 이동 */
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* 링크 기본 스타일 */
.header__link {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.header__link:not(.header__start-button):hover {
  color: #007bff;
}

/* 버튼 스타일 */
button.header__link {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
}

/* 반응형 디자인 - 화면이 작아질 때의 처리 */
@media (max-width: 768px) {
  .header__container {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .header__nav, .header__auth {
    margin-top: 1rem;
    width: 100%;
  }

  .header__nav {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .header__auth {
    justify-content: space-between;
  }

  .header__start-button::after {
    bottom: -40px;
    z-index: 30;
  }

  .header__start-button:hover::after {
    bottom: -45px;
  }
}

/* 기존 스타일은 유지하고 아래 스타일을 수정합니다 */

.header__start-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 23px; /* 라운딩을 23px로 변경 */
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
}

.header__start-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header__start-button::after {
  content: "무료로 자소서 작성";
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease, bottom 0.3s ease;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.header__start-button::before {
  content: "";
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #007bff;
  opacity: 0;
  transition: opacity 0.3s ease, bottom 0.3s ease;
}

.header__start-button:hover::after,
.header__start-button:hover::before {
  opacity: 1;
  bottom: -35px;
}

.header__start-button:hover::before {
  bottom: -27px;
}

@media (max-width: 768px) {
  .header__start-button::after {
    bottom: -50px;
  }

  .header__start-button::before {
    bottom: -42px;
  }

  .header__start-button:hover::after {
    bottom: -45px;
  }

  .header__start-button:hover::before {
    bottom: -37px;
  }
}

@media (max-width: 1200px) {
  .header__container {
    padding: 1rem 50px;  /* 화면이 작아질 때 여백 줄이기 */
  }
}

@media (max-width: 768px) {
  .header__container {
    padding: 1rem 20px;  /* 모바일에서 더 작은 여백 */
  }
  /* ... 기존 모바일 스타일 유지 ... */
}

/* PC 버전 */
.header__desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* 모바일 헤더 스타일 */
.header__mobile {
  display: none;
  width: 100%;
}

.header__mobile-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* 햄버거 메뉴 버튼 */
.header__mobile-menu-button {
  font-size: 24px;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 100;
  color: #333;
  flex: 0 0 auto;
}

/* 모바일 로고 */
.header__mobile-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.header__mobile-logo img {
  height: 24px;
  width: auto;
}

.header__mobile-auth {
  flex: 0 0 auto;
}

/* 모바일 로그인 버튼 */
.header__mobile-auth-button {
  background-color: #007bff;
  color: white;
  padding: 6px 16px;
  border-radius: 23px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
}

.header__mobile-auth-button:hover {
  background-color: #0056b3;
}

/* 모바일 메뉴 드롭다운 */
.header__mobile-menu {
  position: fixed;
  top: 0;
  left: -280px;
  width: 280px;
  height: 100vh;
  background: white;
  transition: left 0.3s ease;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  z-index: 99;
  overflow-y: auto;
}

.header__mobile-menu.active {
  left: 0;
}

.header__mobile-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #333;
  z-index: 100;
}

.header__mobile-nav {
  display: flex;
  flex-direction: column;
  padding: 60px 20px 20px;
}

.header__mobile-link {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.header__mobile-link:hover {
  background-color: #f5f5f5;
}

.header__mobile-start-button {
  margin: 20px;
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 23px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  width: calc(100% - 40px);
  transition: background-color 0.3s ease;
}

.header__mobile-start-button:hover {
  background-color: #0056b3;
}

/* 모바일 메뉴 오버레이 */
.header__mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 98;
}

.header__mobile-overlay.active {
  display: block;
}

@media (max-width: 768px) {
  .header__desktop {
    display: none;
  }
  
  .header__mobile {
    display: block;
  }

  .header__container {
    padding: 0;
  }
}
