.refund-tab {
  max-width: 800px;
  margin: 40px auto 0; /* 상단 여백을 40px로 증가 */
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.refund-tab h2 {
  text-align: left;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.refund-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 25px;
  text-align: left;
  margin-top: 20px; /* 제목과 컨텐츠 사이의 간격 추가 */
}

.refund-content section {
  margin-bottom: 25px;
}

.refund-content h3 {
  color: #28a745;
  margin-bottom: 15px;
  font-weight: 500;
}

.refund-content ol, .refund-content ul {
  padding-left: 20px;
}

.refund-content li {
  margin-bottom: 8px;
  color: #555;
}

.refund-content p {
  margin-bottom: 8px;
  color: #555;
  line-height: 1.6;
}

.refund-actions {
  margin-top: 30px;
  text-align: left;
}

.cancel-subscription-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #dc3545;
  color: white;
}

.cancel-subscription-button:hover {
  background-color: #c82333;
}
