.user-detail-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.user-detail-modal__content {
  background: white;
  padding: 2.5rem;
  border-radius: 16px;
  width: 90%;
  max-width: 550px;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  animation: slideIn 0.3s ease-out;
}

.user-detail-modal__title {
  font-size: 1.75rem;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
  text-align: center;
  line-height: 1.3;
}

.user-detail-modal__description {
  font-size: 1.1rem;
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.user-detail-modal__intro-image {
  width: 120px;
  height: 120px;
  margin: 1.5rem auto;
  display: block;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.user-detail-modal__button {
  background: #2E74FF;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 1rem;
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.15);
}

.user-detail-modal__button:hover {
  background: #1b64ff;
  transform: translateY(-2px);
}

.user-detail-modal__button--secondary {
  background: white;
  color: #2E74FF;
  border: 2px solid #2E74FF;
}

.user-detail-modal__button--secondary:hover {
  background: #f0f7ff;
}

.user-detail-modal__button--skip {
  background: #f8f9fa;
  color: #666;
  border: 1px solid #ddd;
}

.user-detail-modal__button--skip:hover {
  background: #e9ecef;
  color: #444;
}

.user-detail-modal__button:disabled {
  background: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.user-detail-modal__step-indicator {
  width: 45px;
  height: 45px;
  background: linear-gradient(135deg, #4A90E2 0%, #357ABD 100%);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 auto 2rem;
  font-size: 1.2rem;
  box-shadow: 0 4px 12px rgba(74, 144, 226, 0.2);
}

.user-detail-modal__button-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0;
}

.user-detail-modal__field-button {
  padding: 1.2rem 1rem;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  background: white;
  color: #495057;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 0.95rem;
}

.user-detail-modal__field-button:hover {
  border-color: #2E74FF;
  background: #f8f9fa;
}

.user-detail-modal__field-button.selected {
  border-color: #2E74FF;
  background: #f0f7ff;
  color: #2E74FF;
  font-weight: 600;
}

.user-detail-modal__form-group {
  margin-bottom: 1.5rem;
}

.user-detail-modal__form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.user-detail-modal__input,
.user-detail-modal__select {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 2px solid #e9ecef;
  border-radius: 10px;
  font-size: 1rem;
  color: #495057;
  background: white;
  transition: all 0.3s ease;
}

.user-detail-modal__input:focus,
.user-detail-modal__select:focus {
  border-color: #2E74FF;
  outline: none;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.user-detail-modal__completion-container {
  text-align: center;
  padding: 2rem 1rem;
}

.user-detail-modal__completion-title {
  font-size: 2rem;
  font-weight: 800;
  color: #1a1a1a;
  margin: 1.5rem 0;
}

.user-detail-modal__token-message {
  background: #EBF5FF;
  padding: 1.2rem;
  border-radius: 12px;
  margin: 1.5rem 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.user-detail-modal__token-icon {
  font-size: 1.5rem;
}

/* 애니메이션 효과 */
@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.user-detail-modal__content {
  animation: slideIn 0.3s ease-out;
}

.user-detail-modal__navigation {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.user-detail-modal__error {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.user-detail-modal__region-button {
  padding: 0.8rem 1.2rem;
  border: 2px solid #e9ecef;
  border-radius: 10px;
  background: white;
  color: #495057;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  margin: 0.3rem;
}

.user-detail-modal__region-button:hover {
  border-color: #2E74FF;
  background: #f8f9fa;
}

.user-detail-modal__region-button.selected {
  border-color: #2E74FF;
  background: #f0f7ff;
  color: #2E74FF;
  font-weight: 600;
}