.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.login-page {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
}

.login-page h1 {
  display: none;
}

.login-subtitle {
  color: #666;
  margin-bottom: 30px;
  font-size: 1.1em;
}

.login-page form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.input-group {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #007bff;
  font-size: 20px;
  z-index: 100;
  display: block !important;
  pointer-events: none;
}

.login-page input {
  width: 100%;
  padding: 12px 12px 12px 45px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  box-sizing: border-box;
  background-color: white;
}

.login-page input[type="email"] {
  padding-left: 45px;
}

.login-page input[type="password"] {
  padding-left: 45px;
}

.login-page input::placeholder {
  color: #999;
  padding-left: 0px;
}

.login-page input:focus {
  outline: none;
  border-color: #007bff;
}

.login-page button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.login-page button:hover {
  background-color: #0056b3;
}

.login-page button:active {
  transform: scale(0.98);
}

.login-page .links {
  margin: 15px 0;
}

.login-page .links a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.login-page .links a:hover {
  color: #0056b3;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.divider span {
  padding: 0 10px;
  color: #777;
  font-size: 14px;
}

.login-page .social-login {
  margin: 20px 0;
}

.login-page .signup-link {
  color: #666;
  margin-top: 20px;
}

.login-page .signup-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.login-page .signup-link a:hover {
  color: #0056b3;
}

.header-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}
