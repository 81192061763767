.result-step {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.result-step__content {
  background: #fff;
  border-radius: 16px;
  padding: 40px;
}

.result-step__info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
}

.result-step__info-icon {
  width: 24px;
  height: 24px;
  color: #4263eb;
}

.result-step__info-text {
  color: #333;
  font-size: 16px;
}

.result-step__photos-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.result-step__photo-item {
  aspect-ratio: 3/4;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
}

.result-step__photo-item.selected {
  border-color: #4263eb;
}

.result-step__photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-step__photo-selected {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  background: #4263eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.result-step__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}

.result-step__button {
  padding: 16px 48px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.result-step__button--download {
  background: #4263eb;
  color: white;
  border: none;
}

.result-step__button--secondary {
  background: #f1f3f5;
  color: #333;
  border: none;
}

@media (max-width: 768px) {
  .result-step {
    padding: 20px 10px;
  }

  .result-step__content {
    padding: 20px;
  }

  .result-step__photos-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .result-step__photo-item {
    aspect-ratio: 3/4;
  }

  .step-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .step-button {
    width: 100%;
  }
}
