.resume-evaluation-page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 40px 60px;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}

.page-header {
  text-align: center;
  margin-bottom: 40px;
}

.page-header h1 {
  font-size: 2.5em;
  color: #1a1a1a;
  margin-bottom: 10px;
  font-weight: 700;
}

.page-header p {
  color: #666;
  font-size: 1em;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 5px;
}

.input-selection-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 50px 0;
}

.input-option {
  position: relative;
  background: #f8f9fd;
  border-radius: 20px;
  padding: 30px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid transparent;
}

.input-option:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.input-option.selected {
  background: #fff;
  border-color: #2E74FF;
  box-shadow: 0 10px 30px rgba(46, 116, 255, 0.15);
}

.input-option-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.input-option-icon {
  width: 50px;
  height: 50px;
  background: #2E74FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.input-option-title {
  font-size: 1.4em;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.input-option-description {
  color: #666;
  font-size: 0.95em;
  line-height: 1.6;
}

.cover-letter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.cover-letter-card {
  background: #fff;
  border-radius: 16px;
  padding: 25px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid transparent;
}

.cover-letter-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(46, 116, 255, 0.15);
}

.cover-letter-card.selected {
  border-color: #2E74FF;
  background: #f8f9ff;
}

.card-header {
  margin-bottom: 15px;
}

.card-title {
  font-size: 1.2em;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.card-company {
  color: #2E74FF;
  font-size: 0.9em;
  font-weight: 500;
}

.card-content {
  color: #666;
  font-size: 0.9em;
  line-height: 1.6;
}

.card-footer {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #888;
  font-size: 0.85em;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.action-button {
  padding: 15px 40px;
  border-radius: 12px;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary-button {
  background: #2E74FF;
  color: white;
  border: none;
}

.primary-button:hover {
  background: #1b5cd4;
  transform: translateY(-2px);
}

.secondary-button {
  background: #f8f9fd;
  color: #666;
  border: 1px solid #ddd;
}

.secondary-button:hover {
  background: #eef0f7;
}

.text-input-area {
  margin-top: 30px;
  position: relative;
}

.text-input-area textarea {
  width: 100%;
  min-height: 400px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: #f8f9fd;
  font-size: 16px;
  line-height: 1.6;
  resize: vertical;
  transition: all 0.3s ease;
}

.text-input-area textarea:focus {
  outline: none;
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.character-count {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: #666;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border-radius: 6px;
}

.evaluation-button-container {
  margin-top: 40px;
  text-align: center;
}

.submit-button {
  width: 200px;
  padding: 15px 30px;
  background: #2E74FF;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: #1b5cd4;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.input-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin: 20px 0;
    padding: 30px;
    background: #f8fafc;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
}

.input-fields::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(180deg, #2E74FF, #1a56db);
    border-radius: 3px;
}

.input-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.input-field label {
    font-size: 0.95em;
    font-weight: 600;
    color: #2d3748;
    display: flex;
    align-items: center;
    gap: 8px;
}

.input-field label::before {
    content: '•';
    color: #2E74FF;
    font-size: 1.5em;
}

.input-field input {
    padding: 14px 16px;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    font-size: 1em;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.input-field input:hover {
    border-color: #cbd5e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.input-field input:focus {
    outline: none;
    border-color: #2E74FF;
    box-shadow: 0 0 0 4px rgba(46, 116, 255, 0.15);
    transform: translateY(-1px);
}

.input-field input::placeholder {
    color: #a0aec0;
    font-size: 0.95em;
}

/* 입력 필드 아이콘 추가 */
.input-field {
    position: relative;
}

.input-field::after {
    position: absolute;
    right: 16px;
    top: 42px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #a0aec0;
    pointer-events: none;
}

.input-field:nth-child(1)::after {
    content: '\f5ad'; /* 제목 아이콘 */
}

.input-field:nth-child(2)::after {
    content: '\f0b1'; /* 분야 아이콘 */
}

/* 애니메이션 효과 */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.input-field {
    animation: slideIn 0.3s ease-out forwards;
}

.input-field:nth-child(2) {
    animation-delay: 0.1s;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
    .resume-evaluation-page {
        padding: 20px;
        margin: 10px;
        border-radius: 15px;
    }

    .page-header h1 {
        font-size: 1.8em;
    }

    .page-header p {
        font-size: 0.9em;
        padding: 0 10px;
    }

    .input-selection-container {
        grid-template-columns: 1fr;
        gap: 20px;
        margin: 30px 0;
    }

    .input-option {
        padding: 20px;
    }

    .input-option-icon {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .input-option-title {
        font-size: 1.2em;
    }

    .input-fields {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .text-input-area textarea {
        min-height: 200px;
    }

    .cover-letter-cards {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .cover-letter-card {
        padding: 15px;
    }

    .evaluation-button-container {
        padding: 15px;
    }

    .submit-button {
        width: 100%;
        padding: 12px;
        font-size: 1em;
    }

    .input-field {
        width: 100%; /* 전체 너비 사용 */
        margin: 0; /* 여백 제거 */
        padding: 0; /* 패딩 제거 */
    }

    .input-field input,
    .input-field textarea {
        width: 100%; /* 전체 너비 사용 */
        box-sizing: border-box; /* 패딩과 테두리를 포함한 너비 계산 */
        padding: 12px; /* 패딩 조정 */
        border-radius: 5px; /* 모서리 둥글게 */
        border: 1px solid #ccc; /* 테두리 색상 */
    }

    .input-field:nth-child(1) {
        margin-bottom: 15px; /* 첫 번째 입력 필드 간 간격 조정 */
    }
}

/* 유효성 검사 스타일 */
.input-field input:invalid {
    border-color: #fc8181;
}

.input-field input:invalid:focus {
    box-shadow: 0 0 0 4px rgba(252, 129, 129, 0.15);
}

/* 입력 완료 효과 */
.input-field input:valid {
    border-color: #48bb78;
}

.input-field input:valid:focus {
    box-shadow: 0 0 0 4px rgba(72, 187, 120, 0.15);
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(3px);
}

.loading-overlay .loading-text {
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 15px;
}

.no-coverletter {
    text-align: center;
    padding: 40px;
    background: #f8f9fa;
    border-radius: 12px;
    margin: 20px 0;
    border: 1px solid #e4e7ec;
}

.no-coverletter p {
    color: #6c757d;
    margin-bottom: 20px;
    font-size: 16px;
}

.no-coverletter button {
    background-color: #2E74FF;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.no-coverletter button:hover {
    background-color: #1b5cd4;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.15);
}

.cover-letter-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.cover-letter-card {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.cover-letter-card:hover {
    border-color: #2E74FF;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(46, 116, 255, 0.1);
}

.cover-letter-card.selected {
    border: 2px solid #2E74FF;
    background-color: rgba(46, 116, 255, 0.05);
}