.questionSetInfoPage {
  max-width: 900px;
  margin: 40px auto;
  padding: 40px;
  background: #f8faff;
  border-radius: 30px;
}

.questionSetInfoPage h2 {
  font-size: 38px;
  color: #2E74FF;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 700;
}

.questionSetInfoPage p {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
  text-align: center;
}

.cardContainer {
  display: grid;
  gap: 25px;
}

.card {
  background: #ffffff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(46, 116, 255, 0.08);
  transition: all 0.3s ease;
  border: 1px solid rgba(46, 116, 255, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(46, 116, 255, 0.12);
}

.cardHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.cardIcon {
  font-size: 24px;
  color: #2E74FF;
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.textarea {
  width: 100%;
  height: 180px;
  padding: 15px;
  border: 1px solid rgba(46, 116, 255, 0.2);
  border-radius: 12px;
  resize: vertical;
  font-size: 15px;
  background-color: #f8faff;
  transition: all 0.3s ease;
}

.textarea:focus {
  outline: none;
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  background-color: #ffffff;
}

.inputGroup {
  display: flex;
  gap: 10px;
}

.input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid rgba(46, 116, 255, 0.2);
  border-radius: 12px;
  font-size: 15px;
  background-color: #f8faff;
  transition: all 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  background-color: #ffffff;
}

.addButton {
  width: 42px;
  height: 42px;
  border-radius: 12px;
  background: #2E74FF;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.addButton:hover {
  background: #1E5FD9;
  transform: translateY(-2px);
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.tag {
  background: #f0f4ff;
  border-radius: 20px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #2E74FF;
  transition: all 0.3s ease;
}

.tag:hover {
  background: #e5ebff;
}

.tag button {
  background: none;
  border: none;
  color: #2E74FF;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.nextBtn {
  width: 100%;
  padding: 16px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  background: #2E74FF;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nextBtn:not(:disabled):hover {
  background: #1E5FD9;
  transform: translateY(-2px);
}

.nextBtn:disabled {
  background: #B0C4FF;
  cursor: not-allowed;
}

.nextBtn svg {
  font-size: 18px;
}

.inputTypeSelector {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.inputOption {
  flex: 1;
  padding: 25px;
  background: #fff;
  border: 2px solid #e1e5ee;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.inputOption:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.inputOption.selected {
  border-color: #2E74FF;
  background: #f8faff;
}

.inputOptionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.inputOptionIcon {
  font-size: 24px;
  color: #2E74FF;
  margin-right: 12px;
}

.inputOptionHeader h3 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.inputOption p {
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.5;
}

.coverLetterCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.coverLetterCard {
  background: #fff;
  border: 1px solid #e1e5ee;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.coverLetterCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.coverLetterCard.selected {
  border-color: #2E74FF;
  background: #f8faff;
}

.noCoverletter {
  text-align: center;
  padding: 40px;
  background: #f8faff;
  border-radius: 12px;
}

.noCoverletter p {
  margin-bottom: 20px;
  color: #666;
}

.noCoverletter button {
  padding: 10px 20px;
  background: #2E74FF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.noCoverletter button:hover {
  background: #1E5FD9;
}

