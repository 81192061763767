.email-signup-page {
  width: 100%;
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.email-signup-page h1 {
  display: none;
}

.email-signup-page form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-group label {
  width: 120px;
  text-align: left;
  font-weight: bold;
  color: #333;
  flex-shrink: 0;
}

.input-group input,
.input-group select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input-group input:focus,
.input-group select:focus {
  outline: none;
  border-color: #007bff;
}

.email-signup-page button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.email-signup-page button:hover {
  background-color: #0056b3;
}

.verification-button {
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
}

.agreements {
  margin-top: 20px;
}

.agreements label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.agreements label input[type="checkbox"] {
  margin-right: 10px;
}

.agreements label span {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.verification-success,
.verification-failure,
.password-match,
.password-mismatch,
.code-sent-message {
  font-size: 14px;
  margin-top: 5px;
}

.verification-success,
.password-match {
  color: #28a745;
}

.verification-failure,
.password-mismatch {
  color: #dc3545;
}

.code-sent-message {
  color: #007bff;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

/* 모달 관련 스타일은 그대로 유지 */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* 모달의 너비를 설정합니다. */
  max-width: 600px; /* 모달의 최대 너비를 설정합니다. */
  max-height: 80%; /* 모달의 최대 높이를 설정합니다. */
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden; /* 모달 창의 테두리 밖으로 내용이 넘치지 않도록 설정합니다. */
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 모달 뒤의 오버레이 배경색을 설정합니다. */
  z-index: 999;
}

.modal-content {
  padding: 20px;
  max-height: 70vh; /* 모달 창 내에서 스크롤이 가능한 최대 높이 설정 */
  overflow-y: auto; /* 모달 창 내에서 세로 스크롤이 가능하도록 설정합니다. */
  text-align: left; /* 텍스트를 좌측 정렬합니다. */
}

.modal-text {
  font-size: 14px; /* 텍스트 크기를 설정합니다. */
  line-height: 1.6; /* 텍스트 간격을 설정합니다. */
  word-wrap: break-word; /* 긴 단어가 모달 크기를 넘지 않도록 설정합니다. */
  text-align: left; /* 텍스트를 좌측 정렬합니다. */
}

.header-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .email-signup-page {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 20px;
    box-shadow: none;
    border-radius: 0;
  }

  .input-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }

  .input-group label {
    width: 100%;
    text-align: left;
    margin-bottom: 4px;
  }

  .input-group input,
  .input-group select {
    width: 100% !important;
    max-width: 100% !important;
    height: 50px !important;
    box-sizing: border-box !important;
    padding: 14px 16px !important;
    font-size: 16px !important;
    border: 1.5px solid #e4e7ec !important;
    border-radius: 8px !important;
    background-color: #f8faff !important;
  }

  .input-group input:focus {
    border-color: #2E74FF !important;
    background-color: #ffffff !important;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1) !important;
  }

  .verification-button {
    width: 100%;
    margin: 8px 0 0 0;
    padding: 12px;
  }

  .agreements {
    width: 100%;
    padding: 0 10px;
  }

  .agreements label {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .modal {
    width: 90%;
    margin: 10px;
  }

  .modal-content {
    padding: 15px;
    font-size: 14px;
  }

  .header-logo {
    width: 140px;
    margin-bottom: 15px;
  }
}

/* 더 작은 모바일 화면을 위한 추가 스타일 */
@media (max-width: 375px) {
  .email-signup-page {
    padding: 15px;
  }

  .input-group input,
  .input-group select {
    font-size: 14px;
  }

  .agreements label {
    font-size: 13px;
  }
}