.techTreeDetail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.responsibilities {
  font-style: italic;
  color: #555;
  margin-bottom: 20px;
}

.sectionTitle {
  color: #007bff;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.itemName {
  font-weight: bold;
  color: #333;
}

.itemDescription {
  color: #666;
  margin-top: 5px;
}

.backLink {
  display: inline-block;
  margin-top: 30px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.backLink:hover {
  text-decoration: underline;
}