.comment-section {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
}

.comment-section h3 {
    font-size: 20px;
    margin-bottom: 24px;
    color: #1a2b3b;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
}

.comment-section h3::before {
    content: '';
    width: 4px;
    height: 20px;
    background-color: #2E74FF;
    border-radius: 2px;
}

.comment-form {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
}

.comment-form textarea {
    width: 100%;
    min-height: 80px;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    resize: none;
    font-size: 14px;
    line-height: 1.6;
    transition: all 0.2s ease;
    margin-bottom: 12px;
}

.comment-form textarea:focus {
    border-color: #2E74FF;
    outline: none;
    box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.1);
}

.comment-form-actions {
    display: flex;
    justify-content: flex-end;
}

.comment-form button[type="submit"] {
    background-color: #2E74FF;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}

.comment-form button[type="submit"]:hover {
    background-color: #1c5cdb;
}

.comment {
    background-color: #ffffff;
    padding: 16px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f1f5f9;
    transition: all 0.2s ease;
    text-align: left;
}

.comment:last-child {
    border-bottom: none;
}

.comment:hover {
    background-color: #f8fafc;
}

.comment::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: linear-gradient(to bottom, #2E74FF, #87CEFA);
    border-radius: 4px 0 0 4px;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.comment:hover::before {
    opacity: 1;
}

.comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.comment-author {
    display: flex;
    align-items: center;
    gap: 8px;
}

.comment-author-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #e5e9f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #2E74FF;
    font-size: 14px;
}

.comment-author-name {
    font-weight: 600;
    color: #1e293b;
    font-size: 14px;
}

.comment-date {
    font-size: 12px;
    color: #94a3b8;
}

.comment-text {
    color: #334155;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 12px;
    word-break: break-word;
}

.comment-actions {
    display: flex;
    gap: 12px;
}

.comment-actions button {
    background: none;
    border: none;
    padding: 6px 12px;
    font-size: 13px;
    color: #64748b;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.comment-actions button:hover {
    background-color: #f1f5f9;
    color: #2E74FF;
}

.comment-actions .reaction-button {
    color: #2E74FF;
    background-color: #f0f7ff;
}

.comment-actions .reaction-button:hover {
    background-color: #e0f0ff;
}

.comment-actions .delete-button {
    color: #ef4444;
    background-color: #fef2f2;
}

.comment-actions .delete-button:hover {
    background-color: #fee2e2;
}

.replies {
    margin-left: 24px;
    padding-left: 16px;
    border-left: 2px solid #f1f5f9;
}

.depth-1 {
    margin-left: 32px;
}

.comment.deleted {
    background-color: #fafafa;
}

.comment.deleted .comment-text {
    color: #94a3b8;
    font-style: italic;
}

.reply-form {
    margin-top: 16px;
    background-color: #f8fafd;
    border-radius: 12px;
    padding: 16px;
}

.reply-form textarea {
    width: 100%;
    min-height: 80px;
    padding: 12px;
    border: 1px solid #e5e9f2;
    border-radius: 8px;
    font-size: 14px;
    background-color: #ffffff;
}

.reply-form-actions {
    display: flex;
    gap: 8px;
    margin-top: 12px;
}

.reply-form-actions button {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.reply-form-actions button[type="submit"] {
    background-color: #2E74FF;
    color: white;
    border: none;
}

.reply-form-actions button[type="button"] {
    background-color: #f1f5f9;
    color: #64748b;
    border: none;
}

.reaction-button, .reply-button, .delete-button {
    background: none;
    border: none;
    color: #7f8c8d;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    padding: 2px 4px;
    border-radius: 3px;
}

.reaction-button:hover, .reply-button:hover, .delete-button:hover {
    background-color: #e0e0e0;
    color: #333;
}

.depth-0 {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
}

.depth-2 {
    margin-left: 32px;
}

.pagination {
    margin-top: 20px;
    text-align: center;
}

.pagination button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    padding: 6px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 12px;
}

.pagination button:hover {
    background-color: #e0e0e0;
}

.pagination button.active {
    background-color: #3498db;
    color: white;
}

@media (max-width: 768px) {
    .comment-section {
        margin: 15px auto;
        padding: 10px;
    }
    
    .replies {
        margin-left: 16px;
        padding-left: 12px;
    }
    
    .depth-1 {
        margin-left: 20px;
    }
}
