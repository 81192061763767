.payment-page {
  font-family: Arial, sans-serif;
  padding: 40px;
}

.payment-page h2 {
  text-align: left;
  margin-left: 50px;
  margin-bottom: 30px;
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: stretch; /* 높이를 동일하게 만듦 */
  gap: 20px; /* 박스 간 간격 */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.plan-summary-box {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1; /* 1 비율 */
  display: flex;
  flex-direction: column;
  align-items: center; /* 중앙 정렬 */
  text-align: center;
  justify-content: space-between; /* 요소 간 간격 균등 */
}

.payment-info-box {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 2; /* 2 비율 */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* 요소 간 간격 균등 */
}

.plan-header {
  margin-bottom: 20px;
}

.plan-title {
  background: linear-gradient(90deg, #6f42c1, #007bff);
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
}

.plan-details {
  display: flex;
  flex-direction: column; /* 세로 정렬 */
  gap: 20px;
}

.left-section {
  flex: 1;
}

.price {
  font-size: 1.8rem;
  font-weight: bold;
}

.token-slider {
  margin-top: 10px;
}

.slider-range {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #6c757d;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.features li {
  display: flex;
  align-items: center;
}

.features li svg {
  margin-right: 8px;
  color: #28a745;
}

.payment-details h3,
.payment-methods h3 {
  margin-bottom: 15px;
}

.payment-details-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-detail-item {
  display: flex;
  justify-content: space-between;
}

.detail-label {
  color:#bbb;
}

.detail-value {
  color: #007bff;
  font-weight: bold;
}

.methods-buttons {
  display: flex;
  gap: 10px;
}

.methods-buttons button {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f9fa;
  cursor: pointer;
  color: black;
  transition: background-color 0.3s;
}

.methods-buttons button:hover {
  background-color: #e9ecef;
}

.card-image {
  width: 150px;         /* Set the width of the card image */
  height: 100px;        /* Set the height of the card image */
  object-fit: cover;    /* Make sure the image fills the space */
  border-radius: 8px;   /* Optional: rounded corners */
  cursor: pointer;      /* Optional: make the image clickable */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.card-image:hover {
  transform: scale(1.05); /* Slightly zoom in on hover */
}

.masked-card-number {
  margin-top: 10px;
  font-size: 16px;
  color: #767575;  /* 카드 번호 색상 */
}

.billing-option {
  margin-bottom: 10px;
  display: inline-block;
  padding: 5px;
}

.terms {
  margin-top: 20px;
}

.terms label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.payment-action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  max-width: 1200px;
  margin: 20px auto 0;
}

.cancel-btn {
  background-color: white;
  color: #6c757d;
  border: 1px solid #ccc;
  padding: 15px 80px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, border-color 0.3s;
}

.cancel-btn:hover {
  background-color: #f8f9fa;
  border-color: #bbb;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 80px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.confirm-btn:hover {
  background-color: #0056b3;
}

/* "등록 중" 메시지와 오버레이 스타일 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);  /* 흐릿한 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;  /* 화면 상단에 띄우기 */
}

.message {
  color: white;
  font-size: 24px;
  font-weight: bold;
  opacity: 0.7;  /* 흐릿한 효과 */
  animation: fadeIn 0.5s ease-in-out;  /* 애니메이션 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}