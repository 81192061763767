/* PC 버전 스타일 */
.popup {
  position: fixed;
  width: 300px;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 2000;
  user-select: none;
}

/* 모바일 버전 스타일 */
.mobile-popup {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  background-color: #fefefe;
  z-index: 2000;
  overflow-y: auto;
}

.mobile-popup-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.mobile-popup-content {
  padding: 15px;
}

.notification-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.notification-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.notification-time {
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
}

.notification-message {
  font-size: 14px;
  color: #333;
  line-height: 1.4;
}

/* 기존 스타일 유지 */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #4286F5;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.popup-content {
  padding: 15px;
}

.close-button,
.mobile-close-button {
  background: none;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
}

.close-button:hover,
.mobile-close-button:hover {
  color: #ff4d4d;
}
