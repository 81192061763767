.experience-section {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  position: relative;
}

.experience-list {
  margin-bottom: 30px;
}

.experience-item {
  margin-bottom: 30px;
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
}

.experience-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eef1f6;
}

.company-info {
  flex: 1;
  margin-right: 20px;
}

.company-name-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 600;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all 0.3s ease;
}

.company-name-input:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.position-input {
  width: 100%;
  padding: 10px 16px;
  font-size: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.position-input:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.date-range {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.date-range span {
  color: #666;
  font-weight: 500;
}

.date-input {
  padding: 10px 12px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  width: 160px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.date-input:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.description-container {
  margin-top: 20px;
}

.description-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.description-item {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.description-item:hover {
  background-color: #f0f4ff;
}

.bullet-point {
  color: #2E74FF;
  font-size: 20px;
  line-height: 1.5;
}

.description-input {
  flex: 1;
  padding: 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  min-height: 80px;
  resize: vertical;
  font-size: 14px;
  line-height: 1.6;
  transition: all 0.3s ease;
}

.description-input:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.add-description-btn {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  background-color: #f0f4ff;
  color: #2E74FF;
  border: 2px dashed #2E74FF;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.add-description-btn:hover {
  background-color: #e0ebff;
  transform: translateY(-1px);
}

.remove-description-btn {
  padding: 8px;
  background-color: #fff1f1;
  color: #ff4444;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-description-btn:hover {
  background-color: #ffe5e5;
  transform: rotate(90deg);
}

.add-experience-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.add-experience-btn {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #2E74FF, #1b5cd4);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  position: relative;
}

.add-experience-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.add-experience-btn:hover::after {
  content: '경력사항 추가';
  position: absolute;
  right: 70px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.remove-experience-btn {
  padding: 8px 16px;
  background-color: #fff1f1;
  color: #ff4444;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.remove-experience-btn:hover {
  background-color: #ffe5e5;
  color: #ff0000;
}

.experience-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.experience-subtitle {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.experience-item-header {
  margin-bottom: 20px;
}

.experience-item-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.experience-item-subtitle {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
} 