.social-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.social-login-button:hover {
  background-color: #f0f0f0;
}

.social-login-button span {
  margin-left: 10px;
}

.custom-icon {
  width: 20px;
  height: 20px;
}

.social-login-button.google {
  background-color: #4285F4;
  color: white;
  border: none;
}

.social-login-button.google:hover {
  background-color: #357ae8;
}

.social-login-button.google-signup-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.social-login-button.google-signup-btn:hover {
  background-color: #0056b3;
}

.social-login-button.google-signup-btn span {
  margin-left: 10px;
}
