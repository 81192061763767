.post-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post-item {
  background-color: #fff;
  border: 1px solid #e0e0ff;
  border-radius: 12px;
  padding: 24px;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(46, 116, 255, 0.05);
}

.post-item:hover {
  box-shadow: 0 6px 12px rgba(46, 116, 255, 0.1);
  transform: translateY(-2px);
}

.post-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.post-item .post-title {
  font-size: 20px;
  margin-bottom: 8px;
  color: #2E74FF;
  font-weight: 700;
  line-height: 1.3;
}

.post-item .post-excerpt {
  font-size: 16px;
  color: #555;
  margin-bottom: 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #888;
  margin-top: auto;
}

.post-meta span {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.post-likes::before,
.post-comments::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
}

.post-likes::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232E74FF"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>');
}

.post-comments::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232E74FF"><path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/></svg>');
}

.post-date {
  margin-left: auto;
  font-style: italic;
}

.no-posts {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 18px;
  background-color: #f0f4ff;
  border-radius: 12px;
  border: 1px dashed #2E74FF;
}

@media (max-width: 768px) {
  .post-list {
    gap: 8px;
  }

  .post-item {
    padding: 14px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .post-content {
    margin-bottom: 10px;
  }

  .post-title {
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 4px;
  }

  .post-excerpt {
    font-size: 13px;
    line-height: 1.5;
    color: #666;
    -webkit-line-clamp: 2;
    max-height: 3em;
  }

  .post-meta {
    font-size: 11px;
    color: #888;
    gap: 12px;
  }

  .post-meta span {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .no-posts {
    padding: 40px 20px;
    font-size: 14px;
    color: #666;
  }
}
