.options-step {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0 40px 0;
}

.options-step__title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.options-step__subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 60px;
  text-align: center;
}

.options-step__style-grid {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 60px auto;
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}

.options-step__slider {
  display: flex;
  transition: transform 0.3s ease;
  gap: 40px;
  align-items: center;
}

.options-step__style-option {
  flex: 0 0 100%;
  position: relative;
  aspect-ratio: 3/4;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  background: #f5f5f5;
  border: 3px solid transparent;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-step__style-option.selected {
  border-color: #007bff;
  box-shadow: 0 8px 24px rgba(0, 123, 255, 0.2);
}

.options-step__style-option:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.options-step__style-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.options-step__style-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.options-step__style-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  background: linear-gradient(to bottom, 
    rgba(0,0,0,0.7) 0%,
    rgba(0,0,0,0.3) 30%,
    rgba(0,0,0,0.3) 70%,
    rgba(0,0,0,0.7) 100%
  );
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.options-step__style-info h3 {
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.options-step__style-info p {
  font-size: 16px;
  line-height: 1.5;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.options-step__style-option.selected .options-step__style-info {
  opacity: 1;
  background: linear-gradient(to bottom, 
    rgba(0,123,255,0.7) 0%,
    rgba(0,123,255,0.3) 30%,
    rgba(0,123,255,0.3) 70%,
    rgba(0,123,255,0.7) 100%
  );
}

.options-step__footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px;
}

.options-step__button {
  padding: 16px 48px;
  font-size: 18px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
}

.options-step__button:hover {
  background: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

.options-step__button.disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.options-step__nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 24px;
  color: #333;
  transition: all 0.3s ease;
}

.options-step__nav-button:hover {
  background: rgba(255, 255, 255, 1);
  transform: translateY(-50%) scale(1.1);
}

.options-step__nav-button:disabled {
  background: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
  transform: translateY(-50%) scale(1);
}

.options-step__nav-button:first-child {
  left: 10px;
}

.options-step__nav-button:last-child {
  right: 10px;
}

@media (max-width: 768px) {
  .options-step {
    padding: 20px 10px;
  }

  .options-step__title {
    font-size: 24px;
    margin-bottom: 4px;
  }

  .options-step__subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .options-step__style-grid {
    padding: 0 10px;
  }

  .options-step__style-option {
    flex: 0 0 calc(100% - 20px);
    margin: 10px 0;
  }

  .options-step__slider {
    gap: 10px;
  }

  .options-step__style-image {
    width: 90%;
    height: auto;
  }

  .options-step__style-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .options-step__style-info {
    padding: 16px;
    background: linear-gradient(
      to bottom,
      rgba(0,0,0,0.8) 0%,
      rgba(0,0,0,0.4) 30%,
      rgba(0,0,0,0.4) 70%,
      rgba(0,0,0,0.8) 100%
    );
  }

  .options-step__style-info h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .options-step__style-info p {
    font-size: 14px;
    line-height: 1.4;
  }

  .options-step__nav-button {
    width: 36px;
    height: 36px;
    font-size: 18px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .options-step__nav-button:first-child {
    left: 4px;
  }

  .options-step__nav-button:last-child {
    right: 4px;
  }

  .step-buttons {
    padding: 0 16px;
  }

  .step-button {
    width: 100%;
    padding: 14px 24px;
    font-size: 16px;
    border-radius: 12px;
  }
}

@media (max-width: 480px) {
  .options-step__style-grid {
    padding: 0 30px;
  }

  .options-step__nav-button {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

  .options-step__style-info h3 {
    font-size: 18px;
  }

  .options-step__style-info p {
    font-size: 13px;
  }
} 