/* TechTreeGeneratorPage.module.css */
.techTreeContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(46, 116, 255, 0.1);
}

/* 기존 전역 스타일을 수정하여 특정 클래스로 구별 */
.heading {
  font-size: 36px;
  font-weight: 700;
  color: #2E74FF;
  margin: 0 0 40px 0;
  text-align: center;
  letter-spacing: -0.5px;
}


.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.formGroup label {
  flex: 0 0 100px;
  margin-right: 15px;
}

.inputContainer {
  flex: 1;
  position: relative;
}

label {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  display: block;
}

input[type="text"] {
  width: 100%;
  padding: 12px 15px;
  padding-right: 50px;
  font-size: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 4px rgba(46, 116, 255, 0.1);
  outline: none;
}

.charCount {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #777;
}

.submitBtn {
  width: 100%;
  padding: 18px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #2E74FF;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitBtn:not(:disabled):hover {
  background-color: #1c5cd4;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(46, 116, 255, 0.2);
}

.submitBtn:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* 결과 화면의 스타일 */
.techTreeResult {
  margin-top: 40px;
}

.recommendationSection {
  border: 2px solid #e0e0e0;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.recommendationSection:hover {
  box-shadow: 0 8px 20px rgba(46, 116, 255, 0.1);
  transform: translateY(-5px);
}

h4 {
  font-size: 24px;
  font-weight: 700;
  color: #2E74FF;
  margin-bottom: 20px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
}

.copyBtn, .saveBtn {
  flex: 1;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copyBtn {
  background-color: #2E74FF;
}

.copyBtn:hover, .saveBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(46, 116, 255, 0.2);
}

.saveBtn {
  background-color: #28a745;
}

/* ... 기존 스타일 ... */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2E74FF;
  color: #fff;
  padding: 20px 30px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
}

.popup p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
