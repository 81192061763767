.terms-page {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.terms-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.terms-content {
  margin-top: 20px;
  line-height: 1.6;
} 