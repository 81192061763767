.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
}

.pagination button {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.pagination button.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.pagination button:hover:not(.active) {
  background-color: var(--background-color);
}

.pagination-arrow {
  font-weight: bold;
}