.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.sidebar-closed {
  width: 80px;
  padding: 20px 10px;
}

.sidebar-logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4286F5;
  justify-content: center;
}

.sidebar-open .sidebar-logo {
  justify-content: flex-start;
}

.sidebar-logo span {
  margin-left: 10px;
  font-weight: bold;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin: 0;
}

.sidebar-menu-item, .sidebar-menu li a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.sidebar-open .sidebar-menu-item, .sidebar-open .sidebar-menu li a {
  justify-content: flex-start;
}

.sidebar-closed .sidebar-menu-item, .sidebar-closed .sidebar-menu li a {
  justify-content: center;
}

.sidebar-menu-item:hover, .sidebar-menu li a:hover {
  background-color: #34495e;
}

.sidebar-menu-item {
  cursor: pointer;
}

.sidebar-menu li span {
  margin-left: 10px;
}

.sidebar-submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-submenu li {
  margin: 0;
}

.sidebar-submenu li a {
  padding: 10px 10px 10px 30px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s;
}

.sidebar-submenu li a:hover {
  background-color: #34495e;
}

.sidebar-user-info {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-open .sidebar-user-info {
  align-items: flex-start;
}

.sidebar-username, .sidebar-tokens {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sidebar-username span, .sidebar-tokens span {
  margin-left: 10px;
}

.sidebar-tokens {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar-tokens:hover {
  background-color: #34495e;
}

.sidebar-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-logout-btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-open .sidebar-logout-btn {
  justify-content: flex-start;
}

.sidebar-closed .sidebar-logout-btn {
  justify-content: center;
}

.sidebar-logout-btn:hover {
  background-color: #3d566e;
}

.sidebar-logout-btn span {
  margin-left: 10px;
}

.sidebar-closed .sidebar-logo span,
.sidebar-closed .sidebar-menu li span,
.sidebar-closed .sidebar-username span,
.sidebar-closed .sidebar-tokens span,
.sidebar-closed .sidebar-logout-btn span {
  display: none;
}

.sidebar-menu .active {
  background-color: rgba(46, 116, 255, 0.1);
  color: #2E74FF;
}

.sidebar-menu .active svg {
  color: #2E74FF;
}

.admin-page {
  padding: 20px;
}

.admin-page h1 {
  margin-bottom: 20px;
  color: #333;
}