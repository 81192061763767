.resume-preview-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.preview-actions {
  display: flex;
  gap: 15px;
}

.preview-actions button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  background-color: #2E74FF;
  color: white;
  border: none;
}

.preview-actions button:first-child {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #333;
}

.preview-actions button:hover {
  opacity: 0.9;
}

.preview-actions button:first-child:hover {
  background-color: #e5e5e5;
}

.print-button {
  background-color: #2E74FF;
  color: white;
  border: none;
}

.preview-content {
  background-color: white;
  padding: 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media print {
  body * {
    visibility: hidden;
  }
  
  .preview-content,
  .preview-content * {
    visibility: visible;
  }
  
  .preview-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
  
  .preview-header {
    display: none;
  }
}

.template-selector-container {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.template-option {
  padding: 8px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.template-option:hover {
  border-color: #0052CC;
  background: #f0f5ff;
}

.template-option.active {
  background: #0052CC;
  color: white;
  border-color: #0052CC;
}

.template-name {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
}

.template-option:hover {
  border-color: #2E74FF;
}

.template-option.active {
  background: #2E74FF;
}

.template-option.active .template-name {
  color: white;
}

.template-preview-image {
  width: 100%;
  height: 160px;
  margin-bottom: 15px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.template-description {
  font-size: 14px;
  color: #666;
  text-align: center;
  line-height: 1.4;
}

.template-option:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.template-option.active {
  border-color: #2E74FF;
  background: #f0f7ff;
}

/* 모던 템플릿 버튼 */
.template-option[data-type="modern"] {
  background: linear-gradient(135deg, #2E74FF, #87CEFA);
}

/* 클래식 템플릿 버튼 */
.template-option[data-type="classic"] {
  background: linear-gradient(135deg, #2C3E50, #34495E);
}

/* 심플 템플릿 버튼 */
.template-option[data-type="simple"] {
  background: linear-gradient(135deg, #95A5A6, #7F8C8D);
}

.template-option:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.template-option.active {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* 모던 템플릿 스타일 강화 */
.resume-template.template-modern {
  background: #fff;
}

.resume-template.template-modern .resume-container {
  padding: 60px;
  background: linear-gradient(to bottom right, #ffffff, #f8f9ff);
  border-radius: 20px;
  box-shadow: 
    0 20px 40px rgba(0,0,0,0.05),
    0 1px 3px rgba(0,0,0,0.05);
}

.resume-template.template-modern .resume-title {
  border: none;
  position: relative;
  padding: 60px;
}

.resume-template.template-modern .resume-title h1 {
  font-size: 42px;
  background: linear-gradient(45deg, #2E74FF, #87CEFA);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 8px;
}

.resume-template.template-modern .resume-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #2E74FF, #87CEFA);
}

.resume-template.template-modern .info-table {
  border: none !important;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  margin: 40px 0;
}

.resume-template.template-modern .info-table th {
  background: #2E74FF !important;
  color: white !important;
  font-weight: 500;
  padding: 15px 20px;
  border: none !important;
}

.resume-template.template-modern .info-table td {
  padding: 15px 20px;
  border: 1px solid #eef1ff !important;
  background: white;
}

.resume-template.template-modern .photo-cell {
  background: white;
  border: none !important;
  padding: 15px !important;
}

.resume-template.template-modern .profile-photo {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.resume-template.template-modern .section h2 {
  font-size: 24px;
  color: #2E74FF;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border: none;
  position: relative;
}

.resume-template.template-modern .section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2E74FF, #87CEFA);
}

.resume-template.template-modern .content-table {
  border: none !important;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  margin: 20px 0;
}

.resume-template.template-modern .content-table th {
  background: #f0f4ff !important;
  color: #2E74FF !important;
  font-weight: 500;
  padding: 15px 20px;
  border: none !important;
}

.resume-template.template-modern .content-table td {
  padding: 15px 20px;
  border: 1px solid #eef1ff !important;
}

.resume-template.template-modern .content-table tr:hover {
  background-color: #f8f9ff;
}

/* 클래식 템플릿 스타일 */
.resume-template.template-classic .resume-container {
  padding: 50px;
  border: 3px double #000;
  background: #fff;
  position: relative;
}

.resume-template.template-classic .resume-title {
  border-bottom: 3px solid #000;
  margin-bottom: 40px;
}

.resume-template.template-classic .resume-title h1 {
  font-size: 38px;
  font-family: 'Noto Serif KR', serif;
  letter-spacing: 12px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}

.resume-template.template-classic .info-table,
.resume-template.template-classic .content-table {
  border: 2px solid #000;
}

.resume-template.template-classic .info-table th,
.resume-template.template-classic .content-table th {
  background: #f0f0f0;
  border: 1.5px solid #000;
  padding: 15px;
}

.resume-template.template-classic .info-table td,
.resume-template.template-classic .content-table td {
  border: 1.5px solid #000;
  padding: 15px;
}

.resume-template.template-classic .section h2 {
  font-size: 24px;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
  letter-spacing: 4px;
}

/* 심플 템플릿 스타일 */
.resume-template.template-simple .resume-container {
  padding: 60px;
  background: #fff;
  border: none;
  box-shadow: none;
}

.resume-template.template-simple .resume-title {
  border: none;
  margin-bottom: 50px;
}

.resume-template.template-simple .resume-title h1 {
  font-size: 32px;
  color: #333;
  letter-spacing: 4px;
  font-weight: 500;
}

.resume-template.template-simple .info-table,
.resume-template.template-simple .content-table {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.template-simple .content-table th {
  border: none;
  border-bottom: 2px solid #eee;
  padding: 15px;
  color: #555;
  font-weight: 500;
}

.template-simple .content-table td {
  border: none;
  border-bottom: 1px solid #eee;
  padding: 15px;
}

.template-simple .section h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}