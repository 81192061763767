.post-detail-container {
  position: relative;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.back-button {
  position: absolute;
  left: -140px;

  padding: 10px 15px;
  background-color: #ffffff;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.back-button:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

.post-detail {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 50px;  /* 버튼을 위한 공간 확보 */
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.post-title {
  font-size: 24px;
  color: #333;
  margin: 0;
  text-align: left;
  flex-grow: 1;
}

.post-date {
  font-size: 14px;
  color: #888;
  white-space: nowrap;
  margin-left: 20px;
}

.post-meta {
  display: flex;
  justify-content: flex-start;
  color: #888;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.author {
  font-weight: bold;
}

.post-content {
  font-size: 16px;
  line-height: 1.8;
  color: #333;
  margin-bottom: 30px;
}

.post-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.action-button, .comment-count, .views {
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.action-button:hover, .comment-count:hover {
  color: #2171d1;
}

.action-button.liked {
  color: #e74c3c;
}

.author-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.edit-button, .delete-button {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 80px !important;
  height: 36px !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
}

/* CommentSection styles */
.comment-section h3 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
}

.comment-form textarea, .reply-form textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  margin-bottom: 10px;
}

.comment-form button, .reply-form button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.comment-form button:hover, .reply-form button:hover {
  background-color: #e74c3c;
}

.comment {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
}

.comment:last-child {
  border-bottom: none;
}

.comment-content {
  margin-bottom: 15px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.comment-author {
  font-weight: bold;
  color: #333;
}

.comment-date {
  color: #888;
  font-size: 12px;
}

.comment-text {
  font-size: 14px;
  line-height: 1.6;
  color: #444;
  margin-bottom: 10px;
}

.comment-actions {
  display: flex;
}

.reaction-button, .reply-button {
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 14px;
  margin-right: 15px;
}

.reaction-button:hover, .reply-button:hover {
  color: #e74c3c;
}

.reply-form {
  margin-top: 1rem;
}

.replies {
  margin-left: 30px;
  border-left: 2px solid #e0e0e0;
  padding-left: 20px;
}

.depth-1 {
  margin-left: 30px;
}

.depth-2 {
  margin-left: 60px;
}

.report-button {
  background: none;
  border: none;
  color: #e74c3c;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.report-button:hover {
  background-color: #fde8e8;
}

@media (max-width: 768px) {
  .post-detail-container {
    margin: 10px;
    padding: 0;
  }
  .back-button {
    position: relative; 
    left: -140px; /* 버튼을 왼쪽으로 이동 */
    display: inline-flex;
    margin-bottom: 12px;
    padding: 8px 12px;
    font-size: 13px;
    background-color: #f8f9fa;
  }

  .post-detail {
    padding: 16px;
    border-radius: 12px;
  }

  .post-header {
    gap: 8px;
  }

  .post-title {
    font-size: 18px;
    line-height: 1.4;
  }

  .post-meta {
    font-size: 12px;
    margin: 8px 0;
  }

  .post-content {
    font-size: 14px;
    line-height: 1.6;
    margin: 16px 0;
  }

  .post-actions {
    padding: 12px 0;
    gap: 12px;
  }

  .action-button,
  .report-button {
    font-size: 12px;
    padding: 6px 10px;
    border-radius: 15px;
  }
}
