.certificates-section {
  padding: 20px;
}

.certificate-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
}

.cert-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
}

.cert-info input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-certificate-form {
  margin-top: 20px;
  padding: 20px;
  border: 1px dashed #ccc;
  border-radius: 8px;
}

.add-certificate-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-certificate-form button {
  width: 100%;
  padding: 10px;
  background-color: #2E74FF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button {
  padding: 8px 15px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
} 