.photo-upload-step {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.photo-upload-step__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.photo-upload-step__subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 40px;
}

.photo-upload-step__guide {
  padding: 0;
}

.photo-upload-step__guide-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
}

.photo-upload-step__guide-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.photo-upload-step__guide-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.photo-upload-step__guide-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.photo-upload-step__guide-row {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.photo-upload-step__guide-label {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.photo-upload-step__guide-icon {
  font-size: 16px;
}

.photo-upload-step__guide-label.invalid {
  background: #FFE3E3;
  color: #E03131;
}

.photo-upload-step__guide-label.valid {
  background: #D3F9D8;
  color: #2B8A3E;
}

.photo-upload-step__guide-text {
  font-size: 14px;
  color: #495057;
  margin: 0;
}

.photo-upload-step__guide-images {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.photo-upload-step__guide-images img {
  width: 100px;
  height: 133px;
  object-fit: cover;
  border-radius: 8px;
}

.photo-upload-step__main {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
}

.photo-upload-step__upload-section,
.photo-upload-step__additional-section {
  flex: 1;
}

.photo-upload-step__section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.photo-upload-step__required {
  color: #FA5252;
}

.photo-upload-step__upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border: 2px dashed #e9ecef;
  border-radius: 12px;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.photo-upload-step__upload-area:hover {
  border-color: #4263eb;
  background: #f8f9fa;
}

.photo-upload-step__upload-icon {
  width: 48px;
  height: 48px;
  background: #E7F5FF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4263EB;
  font-size: 24px;
}

.photo-upload-step__file-info {
  font-size: 14px;
  color: #868E96;
}

.photo-upload-step__buttons {
  display: flex;
  gap: 16px;
  margin-top: 40px;
}

.photo-upload-step__button {
  flex: 1;
  padding: 16px 32px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.photo-upload-step__button--secondary {
  background: #fff;
  border: 1px solid #ddd;
  color: #333;
}

.photo-upload-step__button--secondary:hover {
  background: #f8f9fa;
}

.photo-upload-step__button--primary {
  background: #4263eb;
  border: none;
  color: white;
}

.photo-upload-step__button--primary:hover {
  background: #364fc7;
}

.photo-upload-step__button--primary:disabled {
  background: #e9ecef;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .photo-upload-step__main {
    flex-direction: column;
  }
  
  .photo-upload-step__guide-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .photo-upload-step__guide-images {
    overflow-x: auto;
    padding-bottom: 8px;
  }
  
  .photo-upload-step__buttons {
    flex-direction: column;
  }
} 

.photo-upload-step__section-container {
  background: #fff;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.photo-upload-step__gender-section {
  margin-bottom: 20px;
}

.photo-upload-step__gender-options {
  display: flex;
  gap: 20px;
  margin-top: 16px;
}

.photo-upload-step__gender-option {
  flex: 1;
  padding: 24px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

.photo-upload-step__gender-option.selected {
  border-color: #4263eb;
  background: #fff;
  box-shadow: 0 4px 12px rgba(66, 99, 235, 0.1);
}

.photo-upload-step__gender-option input[type="radio"] {
  display: none;
}

.photo-upload-step__gender-icon {
  font-size: 32px;
  color: #868e96;
  transition: all 0.3s ease;
}

.photo-upload-step__gender-option.selected .photo-upload-step__gender-icon {
  color: #4263eb;
}

.photo-upload-step__gender-option span {
  font-size: 16px;
  font-weight: 500;
  color: #495057;
  transition: all 0.3s ease;
}

.photo-upload-step__gender-option.selected span {
  color: #4263eb;
}

.photo-upload-step__gender-option:hover {
  border-color: #4263eb;
  transform: translateY(-2px);
}

.photo-upload-step__preview {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.photo-upload-step__preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-upload-step__remove-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(255, 255, 255, 0.95);
  color: #e03131;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.photo-upload-step__remove-button svg {
  width: 18px;
  height: 18px;
}

.photo-upload-step__remove-button:hover {
  background: #fff;
  color: #c92a2a;
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.photo-upload-step__remove-button:active {
  transform: scale(0.95);
}