.resume-builder-view {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.view-header h2 {
  font-size: 24px;
  color: #333;
}

.view-actions button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.view-actions button:hover {
  background-color: #e0e0e0;
}

.view-content {
  background-color: white;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
} 