.App {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex: 1;
  justify-content: center;
}

.page-content {
  flex: 1;
  margin-left: 250px;
  transition: all 0.3s ease;
  min-height: 100vh;
  background-color: #ffffff;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
}

.page-content.content-closed {
  margin-left: 80px;
  max-width: calc(1200px + 170px);
}

.sidebar {
  transition: width 0.3s ease;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .page-content {
    max-width: none;
    margin-left: 0 !important;
    margin-bottom: 60px;
    padding: 0;
  }

  .content-wrapper {
    padding: 0;
  }

  .page-content.content-closed {
    margin-left: 0 !important;
    max-width: none;
  }

  .sidebar {
    display: none;
  }
}
