.photo-generator-container {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.photo-generator-container h1,
.photo-generator-container h2 {
  text-align: left;
  margin-bottom: 20px;
}

.photo-upload-step,
.options-step,
.result-step {
  text-align: center;
}

.photo-upload-step__title,
.photo-upload-step__subtitle {
  text-align: left;
}

.photo-upload-step__upload-container {
  display: flex;
  justify-content: space-between;
}

.photo-upload-step__upload-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48%;
}

.photo-upload-step__label {
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
}

.photo-upload-step__required {
  color: red;
}

.photo-upload-step__input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.photo-upload-step__file-list {
  flex: 1;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
  margin-right: 20px;
}

.photo-upload-step__file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.photo-upload-step__file-name {
  flex: 1;
  padding-left: 10px;
}

.photo-upload-step__remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}

.photo-upload-step__input-file {
  display: none;
}

.photo-upload-step__upload-status {
  margin-top: 10px;
  color: #555;
}

.photo-upload-step__upload-button-container {
  display: flex;
  justify-content: center;
}

.photo-upload-step__upload-button {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  justify-content: center;
}

.photo-upload-step__upload-button:hover {
  background-color: #0056b3;
}

.photo-upload-step__upload-icon {
  margin-right: 5px;
}

.photo-upload-step__next-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.photo-upload-step__next-button:disabled {
  background-color: #cccccc;
}

.image-guide {
  padding: 24px;
}

.image-guide__title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
}

.image-guide__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.image-guide__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.image-guide__header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.image-guide__label {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.image-guide__icon {
  font-size: 16px;
}

.image-guide__label.invalid {
  background: #FFE3E3;
  color: #E03131;
}

.image-guide__label.valid {
  background: #D3F9D8;
  color: #2B8A3E;
}

.image-guide__description {
  font-size: 14px;
  color: #495057;
  line-height: 1.5;
  margin: 0;
}

.image-guide__images {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding: 4px;
  -webkit-overflow-scrolling: touch;
}

.image-guide__images img {
  width: 120px;
  height: 160px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .image-guide__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .image-guide__images img {
    width: 100px;
    height: 133px;
  }
}

.options-step__section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.options-step__label {
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
}

.options-step__required {
  color: red;
}

.options-step__radio-group {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.options-step__radio-option {
  margin-right: 20px;
}

.options-step__style-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 40px;
  box-sizing: border-box;
}

.options-step__style-container {
  display: flex;
  transition: transform 0.3s ease;
  width: 100%;
}

.options-step__style-option {
  flex: 0 0 calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
  cursor: pointer;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 10px;
}

.options-step__style-option.selected {
  border: 2px solid #007bff;
}

.options-step__style-option img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.options-step__style-option span {
  margin-top: 5px;
  text-align: center;
}

.options-step__nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.options-step__nav-button:hover {
  background-color: #0056b3;
}

.options-step__nav-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.options-step__nav-button:first-child {
  left: 0;
}

.options-step__nav-button:last-child {
  right: 0;
}

.options-step__button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  text-align: center;
}

.options-step__button.disabled {
  background-color: #cccccc;
}

.options-step__buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.options-step__loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-step__loading-text {
  color: white;
  font-size: 20px;
}

@media (max-width: 768px) {
  .options-step__style-option {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .options-step__style-option {
    flex: 0 0 calc(100% - 20px);
  }
}

/* 공통 버튼 스타일 */
.step-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}

.step-button {
  padding: 16px 48px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.step-button--secondary {
  background: #f1f3f5;
  color: #333;
}

.step-button--secondary:hover {
  background: #e9ecef;
}

.step-button--primary {
  background: #4263eb;
  color: white;
  font-weight: 500;
}

.step-button--primary:hover {
  background: #364fc7;
}

.step-button--primary:disabled {
  background: #adb5bd;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .step-buttons {
    flex-direction: column;
  }
  
  .step-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .photo-generator-container {
    width: 100%;
    padding: 20px;
  }

  .photo-upload-step__upload-container {
    flex-direction: column;
  }

  .photo-upload-step__upload-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .photo-upload-step__gender-options {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .photo-upload-step__gender-option {
    width: 120px;
  }

  .photo-upload-step__preview-image {
    max-width: 100%;
    height: auto;
  }
}