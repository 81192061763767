.footer {
  padding: 40px 60px;
  background-color: #F6F6F6;
  font-family: Arial, sans-serif;
  color: #333;
  border-top: 1px solid #ddd;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.footer-left, .footer-right {
  flex: 1;
  text-align: left;
}

.footer-left h4, .footer-right h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-left p, .footer-right p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.footer-left a, .footer-right a {
  color: #666;
  text-decoration: none;
}

.footer-left a:hover, .footer-right a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo img {
  width: 150px;
  height: auto;
  margin-bottom: 15px;
}

.footer-logo p {
  margin: 3px 0;
  font-size: 12px;
  color: #666;
  text-align: left;
}

.footer-social-icons {
  display: flex;
  gap: 15px;
}

.footer-social-icons a {
  color: #666;
  font-size: 24px;
  transition: color 0.2s ease;
}

.footer-social-icons a:hover {
  color: #333;
}

.footer-policy {
  text-align: center;
  margin-top: 20px;
}

.footer-policy a {
  color: #666;
  text-decoration: none;
  margin: 0 15px;
}

.footer-policy a:hover {
  text-decoration: underline;
}

.copyright {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
}

/* 모바일 스타일 추가 */
@media (max-width: 768px) {
  .footer {
    padding: 20px 15px; /* 패딩 줄이기 */
  }

  .footer-container {
    flex-direction: column; /* 세로 방향으로 변경 */
    align-items: center; /* 중앙 정렬 */
  }

  .footer-left, .footer-right {
    text-align: center; /* 중앙 정렬 */
    margin-bottom: 20px; /* 하단 여백 추가 */
  }

  .footer-social-icons {
    justify-content: center; /* 중앙 정렬 */
  }

  .footer-social-icons a {
    font-size: 20px; /* 아이콘 크기 조정 */
  }

  .footer-policy {
    margin-top: 10px; /* 여백 조정 */
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-logo p {
    text-align: center;
  }

  .footer-social-icons {
    margin-top: 15px;
  }
}
