.reset-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 1rem; /* 페이지 전체의 padding 추가 */
}

.reset-password-page h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.reset-password-page form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  gap: 1rem; /* 요소들 사이의 간격 추가 */
}

.reset-password-page form .input-field {
  height: 2.5rem;
  padding: 0 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.reset-password-page form .input-field:focus {
  border-color: #007bff;
  outline: none;
}

.reset-password-page form button {
  height: 2.5rem;
  padding: 0 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-password-page form button:hover {
  background-color: #0056b3;
}

.code-sent-message,
.verification-message {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #007bff;
}

.fail-test {
  margin-top: 0.5rem;
  font-size: 0.88rem;
  color: #dc3545;
}

.password-match {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #28a745;
}

.password-mismatch {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #dc3545;
}