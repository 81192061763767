.spinning-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 1001;
}

.spinning-logo {
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
}

.loading-text {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.button-spinner {
    width: 20px;
    height: 20px;
    animation: spin 1.5s linear infinite;
    margin-right: 8px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} 