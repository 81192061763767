.privacy-tab {
  max-width: 800px;
  margin: 40px auto 0; /* 상단 여백을 40px로 증가 */
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.privacy-tab h2 {
  text-align: left; /* 왼쪽 정렬로 변경 */
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.privacy-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 25px;
  text-align: left; /* 왼쪽 정렬로 변경 */
  margin-top: 20px; /* 제목과 컨텐츠 사이의 간격 추가 */
}

.privacy-content section {
  margin-bottom: 25px;
}

.privacy-content h3 {
  color: #007bff;
  margin-bottom: 15px;
  font-weight: 500;
}

.privacy-content ul {
  padding-left: 20px;
  list-style-type: circle;
}

.privacy-content li {
  margin-bottom: 8px;
  color: #555;
}

.privacy-content p {
  color: #555;
  line-height: 1.6;
}
