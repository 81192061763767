.questionSetInfoPage {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(46, 116, 255, 0.1);
  font-family: 'Noto Sans KR', sans-serif;
}

.questionSetInfoPage h2 {
  font-size: 36px;
  color: #2E74FF;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.questionSetInfoPage p {
  font-size: 18px;
  color: #555;
  margin-bottom: 30px;
  text-align: center;
}

.categorySelector {
  margin-bottom: 30px;
}

.select {
  width: 100%;
  padding: 15px;
  border: 2px solid #2E74FF;
  border-radius: 15px;
  font-size: 16px;
  background-color: #F0F8FF;
  transition: all 0.3s ease;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.3);
  background-color: #FFFFFF;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.backButton, .generateButton, .saveButton {
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.backButton {
  background-color: #F0F8FF;
  color: #2E74FF;
  border: 2px solid #2E74FF;
}

.backButton:hover {
  background-color: #2E74FF;
  color: #FFFFFF;
}

.generateButton {
  background-color: #2E74FF;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generateButton:hover:not(:disabled) {
  background-color: #1E5FD9;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(46, 116, 255, 0.3);
}

.generateButton:disabled {
  background-color: #B0C4FF;
  cursor: not-allowed;
  opacity: 0.7;
}

.questionsContainer {
  margin-top: 30px;
  padding: 30px;
  background-color: #F0F8FF;
  border-radius: 15px;
  position: relative;
}

.toggleButtonContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}

.toggleAnswersButton {
  width: 64px;
  height: 32px;
  cursor: pointer;
}

.questionAnswerPair {
  margin-bottom: 30px;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(46, 116, 255, 0.1);
}

.question {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.answer {
  display: flex;
  align-items: flex-start;
}

.answerIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  margin-top: 3px;
}

.answer span {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.saveButton {
  background-color: #2E74FF;
  color: #FFFFFF;
  display: block;
  width: 100%;
  margin-top: 30px;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.loadingOverlay :global(.loading-text) {
  color: white !important;
}

.countSelector {
  margin: 20px 0;
  text-align: center;
}

.countSelector p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.countButtons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.countButton {
  padding: 10px 30px;
  border: 2px solid #2E74FF;
  border-radius: 10px;
  background: #FFFFFF;
  color: #2E74FF;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.countButton:hover {
  background: #F0F8FF;
}

.countButton.selected {
  background: #2E74FF;
  color: #FFFFFF;
}


