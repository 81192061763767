.admin-tabs {
    margin-bottom: 20px;
}

.admin-tabs button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
}

.admin-tabs button.active {
    background-color: #2c3e50;
    color: white;
}

/* 관리자 페이지 공통 스타일 */
.admin-page {
    padding: 2rem;
    background-color: #f8f9fa;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.admin-title {
    font-size: 1.8rem;
    color: #2c3e50;
}

.admin-content {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1.5rem;
}

/* 테이블 스타일 개선 */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: white;
}

th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

th {
    background-color: #f8f9fa;
    color: #495057;
    font-weight: 600;
}

tr:hover {
    background-color: #f8f9fa;
}

/* 버튼 스타일 */
.delete-btn, .edit-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.delete-btn {
    background-color: #ff6b6b;
    color: white;
    margin-left: 8px;
}

.edit-btn {
    background-color: #4dabf7;
    color: white;
}

.delete-btn:hover {
    background-color: #ff4444;
}

.edit-btn:hover {
    background-color: #339af0;
}

/* 검색 영역 스타일 */
.admin-search {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
} 