.questionViewPage {
  padding: 20px;
  margin-left: 40px; /* sidebar가 열려있을 때의 너비 고려 */
}

.questionViewPage h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.questionsContainer {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  border: 1px solid #ddd;
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .questionsContainer {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .questionsContainer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.toggleButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
}

.toggleAnswersButton {
  width: 64px;
  height: 32px;
  cursor: pointer;
}

.questionAnswerPair {
  margin-bottom: 20px;
  text-align: left;
}

.question {
  margin-bottom: 10px;
  font-weight: bold;
}

.answer {
  display: flex;
  align-items: center;
}

.answerIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.answer span {
  text-align: left;
}

.backButton {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.backButton:hover {
  background-color: #0056b3;
}
