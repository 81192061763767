.my-page {
  padding: 20px;
}
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  border-radius: 30px;
  padding: 10px;
}

.tabs a {
  text-decoration: none;
  color: #333;
  padding: 12px 24px;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: bold;
}

.tabs a:hover {
  background-color: #e0e0e0;
  border-radius: 50px;
  padding: 12px 30px;
}

.tabs .active,
.tabs a:hover.active {
  background-color: #007bff;
  color: white;
  border-radius: 50px;
  padding: 12px 30px;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.active-indicator {
  display: none;
}

.profile-tab {
  padding: 20px;
}

.user-info {
  display: flex;
  align-items: flex-start;
  margin-top: 20px; /* 상단에 여백 추가 */
  margin-left: 20px;
  padding-top: 20px; /* 상단 패딩 추가 */
}

.user-info img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-details p {
  margin: 5px 0;
}

.edit-profile-btn {
  margin-top: 15px;
  background-color: #1976d2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-profile-btn:hover {
  background-color: #1565c0;
}

.user-stats {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.saved-files {
  margin-top: 20px;
}

.saved-files h3 {
  margin-bottom: 10px;
}

.file-category {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.resume-generator {
  padding: 20px;
}

.resume-generator h2 {
  margin-bottom: 20px;
}

.resume-list {
  display: flex;
  flex-wrap: wrap;
}

.resume-item {
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  position: relative;
}

.temp-tag {
  background-color: #ff0;
  padding: 2px 5px;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* 모바일 스타일 */
@media (max-width: 768px) {
  .my-page {
    padding: 16px;
    margin-top: 60px;
  }

  .tabs {
    display: flex;
    overflow-x: auto; /* 가로 스크롤 가능하도록 설정 */
    white-space: nowrap; /* 탭이 한 줄로 표시되도록 설정 */
    margin-bottom: 10px; /* 아래 여백 조정 */
    padding: 5px 0; /* 상하 패딩 추가 */
  }

  .tabs a {
    text-align: center;
    background-color: #f0f0f0;
    padding: 6px 10px; /* 패딩을 더 줄여서 크기 감소 */
    border-radius: 4px; /* 모서리 둥글기 감소 */
    margin-right: 8px; /* 각 탭 사이의 간격 추가 */
    font-size: 12px; /* 글자 크기 조정 */
    transition: background-color 0.3s, transform 0.2s; /* 부드러운 효과 추가 */
  }

  .tabs a:last-child {
    margin-right: 0; /* 마지막 탭의 오른쪽 여백 제거 */
  }

  .tabs a:hover {
    background-color: #e0e0e0; /* 호버 시 색상 변화 */
    transform: scale(1.05); /* 호버 시 약간 확대 */
  }

  .tabs .active {
    background-color: #007bff; /* 활성화된 탭 색상 */
    color: white; /* 글자 색상 변경 */
  }

  .tabs .active:hover {
    background-color: #0056b3; /* 활성화된 탭 호버 색상 */
  }

  .profile-tab {
    padding: 16px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .user-info {
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 16px;
    background: #f8f9fa;
    border-radius: 12px;
  }

  .user-info img {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }

  .user-details {
    flex: 1;
  }

  .user-details p {
    font-size: 14px;
    margin: 4px 0;
    color: #495057;
  }

  .edit-profile-btn {
    width: auto;
    margin-top: 16px;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 6px;
  }

  .user-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin: 20px 0;
  }

  .saved-files {
    margin-top: 24px;
  }

  .file-category {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 16px;
    margin-bottom: 12px;
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }

  .resume-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .resume-item {
    width: 100%;
    margin: 0;
    padding: 16px;
    background: #fff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
  }
}

/* 작은 모바일 화면 최적화 */
@media (max-width: 375px) {
  .tabs {
    grid-template-columns: 1fr;
  }

  .user-stats {
    grid-template-columns: 1fr;
  }

  .user-info {
    flex-direction: column;
    text-align: center;
  }

  .user-info img {
    margin: 0 0 12px 0;
  }
}
