/* RecruitInfoPage.css */
.recruit-info-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f8faff;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 10;
}

.filter-group {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.filter-button-group {
  position: relative;
}

.filter-dropdown {
  padding: 12px 20px;
  border: 1.5px solid #e4e7ec;
  border-radius: 10px;
  background-color: #fff;
  color: #2c3e50;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.filter-dropdown:hover {
  border-color: #2E74FF;
  background-color: #f8faff;
}

.filter-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
  z-index: 20;
  margin-top: 8px;
}

.education-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.location-button, .career-button, .education-button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  background-color: #fff; /* 흰색 배경 */
  color: #000; /* 검정색 글자 */
  cursor: pointer;
}

.location-button.selected, .career-button.selected, .education-button.selected {
  border: 2px solid #007bff; /* 클릭 시 테두리 진하게 */
}

.location-button:hover, .career-button:hover, .education-button:hover {
  background-color: #fff; /* 호버 시 색상 변화 없음 */
}

.experience-inputs {
  margin-top: 10px;
}

.experience-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.experience-input {
  width: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.filter-reset {
  background-color: #fff; /* 흰색 배경 */
  color: #000; /* 검정색 글자 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.filter-reset:hover {
  background-color: #fff; /* 호버 시 색상 변화 없음 */
}

.filter-apply {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.filter-apply:hover {
  background-color: #007bff; /* 호버 시 색상 변화 없음 */
}

.filter-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #fff; /* 흰색 배경 */
  color: #000; /* 검정색 글자 */
}

.filter-select:hover {
  background-color: #fff; /* 호버 시 색상 변화 없음 */
}

.favorite-button {
  background-color: #fff; /* 흰색 배경 */
  color: #000; /* 검정색 글자 */
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.favorite-button:hover {
  background-color: #fff; /* 호버 시 색상 변화 없음 */
}

.recruit-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  padding: 20px;
}

.recruit-item {
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  border: 1px solid #e4e7ec;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  gap: 20px;
  position: relative;
  margin: 0;
  height: auto;
  min-height: 200px;
}

.recruit-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.recruit-thumbnail {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e4e7ec;
}

.recruit-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recruit-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recruit-header {
  flex: 1;
}

.recruit-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
  line-height: 1.4;
}

.recruit-header p {
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
}

.recruit-details {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.recruit-tag {
  padding: 6px 12px;
  background-color: #f0f7ff;
  color: #2E74FF;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.recruit-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
}

.recruit-date {
  margin-right: 20px;
  text-align: right;
}

.favorite-toggle {
  padding: 8px 16px;
  border: 1.5px solid #e4e7ec;
  border-radius: 8px;
  background: #fff;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.favorite-toggle.active {
  background-color: #fff3dc;
  color: #ff9800;
  border-color: #ff9800;
}

@media (max-width: 768px) {
  .recruit-info-page {
    padding: 12px;
    margin-top: 0;
  }

  .filter-bar {
    flex-direction: column;
    padding: 12px;
    gap: 8px;
    margin-bottom: 16px;
  }

  .filter-group {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }

  .filter-dropdown {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    min-width: auto;
  }

  .filter-dropdown-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 320px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1000;
  }

  .recruit-list {
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 12px;
  }

  .recruit-item {
    min-height: 150px;
  }

  .recruit-thumbnail {
    width: 70px;
    height: 70px;
    border-radius: 6px;
  }

  .recruit-header h3 {
    font-size: 15px;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    line-height: 1.3;
    height: auto;
  }

  .recruit-header p {
    font-size: 13px;
    margin-bottom: 8px;
  }

  .recruit-tag {
    font-size: 11px;
    padding: 4px 8px;
  }

  .recruit-footer {
    padding-top: 8px;
    gap: 4px;
  }

  .recruit-date {
    font-size: 11px;
    margin-right: 0;
  }

  .favorite-toggle {
    padding: 6px 12px;
    font-size: 12px;
  }

  .favorite-button {
    width: 100%;
    margin-top: 8px;
    padding: 8px;
    font-size: 14px;
  }

  /* 모달 배경 추가 */
  .filter-dropdown-content::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
}

@media (max-width: 375px) {
  .recruit-info-page {
    padding: 8px;
  }

  .filter-group {
    grid-template-columns: 1fr;
  }

  .recruit-thumbnail {
    width: 60px;
    height: 60px;
  }

  .recruit-header h3 {
    font-size: 14px;
  }
}