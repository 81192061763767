.subscribe-page h2 {
  margin-top: 50px;
  margin-left: 180px;
  margin-bottom: 30px;
  text-align: left;
}

.subscribe-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.plan-cards {
  display: flex;
  justify-content: center; /* 중앙 정렬 */
  gap: 20px;
}

.plan-card {
  flex: none; /* flex 비율 해제 */
  width: 320px; /* 너비 증가 */
  height: 550px; /* 높이 증가 */
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border: 2px solid transparent; /* 기본 테두리 색상 */
  transition: border-color 0.3s ease; /* 활성화 시 부드러운 전환 효과 */
}

.plan-card.active {
  border-color: #007bff; /* 활성화된 플랜 카드의 테두리 파란색 */
}

.plan-header {
  display: flex;
  justify-content: flex-start; /* 제목을 좌측 정렬 */
  margin-bottom: 15px;
}

.plan-title {
  padding: 6px 12px;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
}

.plan-title.free-plan {
  background-color: linear-gradient(90deg, #6f42c1, #007bff);
}

.plan-title.starter-plan {
  background-color: linear-gradient(90deg, #6f42c1, #007bff);
}

.plan-title.professional-plan {
  background-color: linear-gradient(90deg, #6f42c1, #007bff);
}

.price {
  font-size: 2.0rem;
  font-weight: bold;
  text-align: left; /* 가격을 좌측 정렬 */
}

.token-slider {
  margin: 15px 0;
}

.slider-range {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #6c757d;
}

.token-slider input[type="range"] {
  width: 100%;
  margin: 10px 0;
}

.features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.features li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.features li svg {
  margin-right: 8px;
  color: #28a745;
}

.subscribe-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 0;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  margin-top: auto;
}

.subscribe-btn:hover {
  background-color: #0056b3;
}

.subscribe-btn.active {
  background-color: #6c757d;
  cursor: default;
}

/* SubscribePlanPage.css */

/* 전체 화면을 덮는 오버레이 */
.billing-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경을 반투명하게 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* 다른 콘텐츠보다 위에 표시되도록 */
}

/* Modal의 내용 */
.billing-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.billing-modal-content h2 {
  margin-bottom: 20px;
  font-size: 20px;
}

.billing-modal-content p {
  margin-bottom: 20px;
  font-size: 16px;
}

.billing-modal-content button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.billing-modal-content button:hover {
  background-color: #0056b3;
}

/* 모바일 최적화 스타일 */
@media screen and (max-width: 768px) {
  .subscribe-page h2 {
    margin: 30px 20px;
    text-align: center;
  }

  .subscribe-container {
    padding: 0 10px;
  }

  .plan-cards {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .plan-card {
    width: 100%;
    max-width: 340px;
    height: auto;
    min-height: 450px;
    padding: 15px;
  }

  .plan-header {
    justify-content: center;
  }

  .plan-title {
    font-size: 1.1rem;
    text-align: center;
    width: 100%;
  }

  .price {
    font-size: 1.8rem;
    text-align: center;
  }

  .token-slider {
    margin: 10px 0;
  }

  .token-amount {
    text-align: center;
    font-size: 1rem;
  }

  .features {
    margin: 15px 0;
  }

  .features li {
    font-size: 0.9rem;
  }

  .subscribe-btn {
    padding: 12px 0;
    font-size: 1rem;
    width: 100%;
  }
}