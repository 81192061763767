.resume-generator-page2 {
  max-width: 1000px;
  margin: 40px auto;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.resume-generator-page2 h2 {
  font-size: 28px;
  color: #2E74FF;
  margin-bottom: 10px;
  font-weight: 600;
}

.resume-generator-page2 p {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.add-button-container {
  position: relative;
  margin: 40px 0;
  text-align: center;
}

.add-button {
  width: 100%;
  padding: 20px;
  background: linear-gradient(135deg, #2E74FF 0%, #4c8dff 100%);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.add-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: 0.5s;
}

.add-button:hover::before {
  left: 100%;
}

.add-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(46, 116, 255, 0.3);
}

.pulse-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 3px solid #2E74FF;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.8;
  }
  70% {
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.add-button-arrow {
  font-size: 24px;
  color: #2E74FF;
  margin-bottom: 0px;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.start-writing-prompt {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #4a5568;
  font-weight: 500;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.resumepage2-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.back-action-button {
  background-color: #f5f5f5;
  color: #666;
  border: 1px solid #ddd;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.save-action-button,
.temp-save-action-button {
  background-color: #2E74FF;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.save-action-button:hover,
.temp-save-action-button:hover {
  background-color: #1b5cd4;
  transform: translateY(-1px);
}

.question-content {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.question-select {
  width: 70%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 15px;
  color: #333;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.question-select:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.1);
}

.question-actions {
  display: flex;
  gap: 10px;
}

.generate-button, 
.regenerate-button {
  padding: 10px 16px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 6px;
}

.generate-button {
  background-color: #2E74FF;
  color: white;
  border: none;
}

.regenerate-button {
  background-color: #f0f7ff;
  color: #2E74FF;
  border: 1px solid #2E74FF;
}

.generate-button:hover,
.regenerate-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(46, 116, 255, 0.2);
}

.response-textarea {
  width: 100%;
  min-height: 200px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 15px;
  line-height: 1.6;
  resize: vertical;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.response-textarea:focus {
  border-color: #2E74FF;
  box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.1);
}

.char-counter {
  text-align: right;
  color: #666;
  font-size: 14px;
  margin-top: 8px;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #2E74FF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.delete-button {
  padding: 8px;
  background-color: #fff0f0;
  color: #ff4d4d;
  border: 1px solid #ff4d4d;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.delete-button:hover {
  background-color: #ff4d4d;
  color: white;
}

.info-message {
  background-color: #f8f9fa;
  border-left: 4px solid #2E74FF;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #666;
  font-size: 14px;
}

.button-tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.button-tooltip .tooltip-text {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  white-space: nowrap;
}

.button-tooltip:hover .tooltip-text {
  visibility: visible;
}

.icon-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.response-textarea {
  background-color: #fafafa;
  border: 2px solid #e0e0e0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: 'Pretendard', sans-serif;
  line-height: 1.8;
}

.response-textarea:focus {
  background-color: #ffffff;
  border-color: #2E74FF;
  box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.loading-overlay .loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: transparent !important;
  padding: 32px;
  box-shadow: none !important;
}

.loading-content {
  background: transparent;
  box-shadow: none;
}

.loading-text {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

/* 기능 안내 스타일 업데이트 */
.feature-guide {
  background: linear-gradient(145deg, #ffffff 0%, #f8faff 100%);
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 30px;
  border: 2px solid #e4ebff;
  box-shadow: 0 8px 20px rgba(46, 116, 255, 0.08);
  transition: all 0.3s ease;
}

.feature-guide:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 25px rgba(46, 116, 255, 0.12);
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e4ebff;
}

.feature-icon {
  font-size: 28px;
  color: #2E74FF;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.feature-header h3 {
  background: linear-gradient(120deg, #2E74FF, #4c8dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

.feature-steps {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  gap: 15px;
  align-items: start;
}

.feature-step {
  background: white;
  border-radius: 12px;
  padding: 25px;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid #e4ebff;
}

.feature-step:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(46, 116, 255, 0.15);
}

.step-number {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 32px;
  height: 32px;
  background: linear-gradient(135deg, #2E74FF, #4c8dff);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(46, 116, 255, 0.3);
}

.step-content h4 {
  color: #2E74FF;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.step-content p {
  color: #4a5568;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.step-example {
  background: #f8faff;
  border: 1px solid #e4ebff;
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.step-arrow {
  display: flex;
  align-items: center;
  color: #2E74FF;
  font-size: 24px;
  padding: 0 10px;
  margin-top: 40px;
}

.highlight {
  background: linear-gradient(120deg, #2E74FF 0%, #4c8dff 100%);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: 500;
  display: inline-block;
}

/* 반응형 디자인 개선 */
@media (max-width: 968px) {
  .feature-steps {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .step-arrow {
    transform: rotate(90deg);
    margin: 0;
    padding: 10px 0;
  }
}

.info-message {
  background: linear-gradient(145deg, #f8faff 0%, #ffffff 100%);
  border-left: 4px solid #2E74FF;
  padding: 15px 20px;
  margin-bottom: 25px;
  border-radius: 8px;
  color: #4a5568;
  font-size: 15px;
  line-height: 1.6;
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.08);
}

.info-message strong {
  color: #2E74FF;
  font-weight: 600;
}

/* 툴팁 관련 스타일 */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: max-content;
  max-width: 220px;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  border: 1px solid #e4ebff;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip-title {
  color: #2E74FF;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: left;
}

.tooltip-content {
  color: #4a5568;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
}

/* 호버 시 툴팁 표시 */
.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(-5px);
}

/* 기존 버튼 스타일 업데이트 */
.save-action-button,
.temp-save-action-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.save-action-button {
  background: linear-gradient(135deg, #2E74FF 0%, #4c8dff 100%);
  color: white;
  border: none;
}

.temp-save-action-button {
  background: white;
  color: #2E74FF;
  border: 2px solid #2E74FF;
  margin-left: 10px;
}

.save-action-button:hover,
.temp-save-action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .tooltip {
    width: 200px;
    white-space: normal;
  }
  
  .tooltip-content {
    white-space: normal;
  }
  
  .right-buttons {
    display: flex;
    gap: 10px;
  }
  
  .save-action-button,
  .temp-save-action-button {
    padding: 10px 16px;
    font-size: 14px;
  }
}

/* 개선된 스텝 인디케이터 스타일 */
.progress-bar-container {
  margin: 0 auto 40px;
  max-width: 400px;
  padding: 0 20px;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.progress-bar::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background: #e4ebff;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
  border-radius: 4px;
}

.progress-bar::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 50%;
  height: 3px;
  background: linear-gradient(90deg, #2E74FF, #4c8dff);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: 4px;
}

.progress-step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 3px solid #e4ebff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #a0a6b6;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(46, 116, 255, 0.1);
}

.progress-step::after {
  content: attr(data-label);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  color: #666;
}

/* 첫 번째 스텝은 완료 스타일 제거하고 기본 스타일만 적용 */
.progress-step:first-child {
  background: white;
  border-color: #e4ebff;
  color: #a0a6b6;
}

.progress-step:first-child::after {
  color: #666;
  font-weight: normal;
}

/* 두 번째 스텝만 활성화 스타일 적용 */
.progress-step:last-child {
  background: linear-gradient(135deg, #2E74FF, #4c8dff);
  border-color: #2E74FF;
  color: white;
  box-shadow: 0 4px 12px rgba(46, 116, 255, 0.2);
}

.progress-step:last-child::after {
  color: #2E74FF;
  font-weight: 600;
}

/* 기본 컨테이너 스타일 */
.resume-generator-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 24px;
  background-color: #f8faff;
}

/* 모바일 기기 (768px 이하) */
@media screen and (max-width: 768px) {
  .resume-generator-page {
    padding: 24px 16px;
  }

  .page-header {
    padding: 24px;
    margin-bottom: 24px;
  }

  .page-header h1 {
    font-size: 24px;
  }

  .input-card {
    padding: 20px;
  }

  .question-response-block {
    padding: 20px;
  }

  .question-items-group {
    flex-direction: column;
  }

  /* 터치 인터페이스 최적화 */
  .question-dropdown-button {
    min-height: 44px;
    width: 100%;
    margin-top: 10px;
  }

  .action-buttons button {
    padding: 16px;
  }
}

/* 태블릿 기기 (768px ~ 1024px) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .resume-generator-page {
    padding: 32px 20px;
  }
}
