.resume-evaluation-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  margin-right: 20px;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #0056b3;
}

.back-icon {
  margin-right: 5px;
}

.back-text {
  font-size: 14px;
}

h2 {
  margin: 0;
  font-size: 28px;
  color: #2c3e50;
}

.evaluation-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.evaluation-section {
  margin-bottom: 30px;
}

.evaluation-section h3 {
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

.score-list, .strength-list, .improvement-list {
  list-style-type: none;
  padding-left: 0;
}

.score-list li, .strength-list li, .improvement-list li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.score-list li {
  display: flex;
  justify-content: space-between;
}

.score-name {
  font-weight: bold;
}

.score-value {
  font-weight: bold;
  color: #007bff;
}

.total-score, .fit-for-role {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.strength-list, .improvement-list {
  padding-left: 20px;
}

.score-graph {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.wording-feedback {
  margin-top: 30px;
}

.feedback-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.feedback-item.weak {
  background-color: #ffe6e6;
}

.feedback-item.strong {
  background-color: #e6ffe6;
}

.word {
  font-weight: bold;
}

.evaluation-detail-container {
  max-width: 1100px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.detail-header {
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #f3f4f6;
  border: none;
  border-radius: 8px;
  color: #4b5563;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
}

.back-button:hover {
  background: #e5e7eb;
}